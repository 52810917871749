import React from 'react'

export const ButtonNormal = ({
    caption,
    onClick,
    disabled = false,
    loading = false,
}: {
    caption: string
    onClick: () => void
    disabled?: boolean
    loading?: boolean
}) => {
    return (
        <button
            className="px-3 py-1 bg-violetDark hover:bg-violet-600 text-base text-white whitespace-nowrap rounded-2xl disabled:hidden flex items-center justify-center gap-2"
            onClick={onClick}
            disabled={disabled}
        >
            {loading ? <div className="spinner"></div> : null} {caption}
        </button>
    )
}
