import React from 'react'

const Free = () => {
    return (
        <div className="w-full mini:min-h-screen mini:h-full xl:h-height-main bg-lime-900 bg-no-repeat bg-cover md:px-4 relative xl:pb-0">
            <div className="flex justify-center items-center h-full w-screen">
                <img src={require('../../../assets/images/free.jpeg')} alt="" className="h-[60vh] shadow-2xl" />
            </div>
        </div>
    )
}

export default Free
