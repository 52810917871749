import React from 'react'
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange'
import styles from '../love/love.module.css'

interface MoneyType {
    isVisible: boolean
}

const Money: React.FC<MoneyType> = (props: MoneyType): JSX.Element => {
    return (
        <div className={styles.body}>
            <div className={styles.body_content}>
                <div className={styles.title_container}>
                    <div className={props.isVisible ? styles.circle + ' ' + styles.circle_show : styles.circle}>
                        <CurrencyExchangeIcon sx={{ color: '#ffffff', fontSize: 40 }} />
                    </div>
                    <div className={styles.sobrecircle}></div>
                    <div className={styles.title}>
                        DINERO
                        <div className={styles.line}></div>
                    </div>
                </div>
                <div className={styles.parrafo}>
                    <p>
                        El dinero es un recurso importante en nuestras vidas, ya que nos permite satisfacer nuestras
                        necesidades y alcanzar nuestros objetivos financieros. Sin embargo, a veces el dinero puede ser
                        fuente de preocupaciones y estrés.
                    </p>
                    <p>
                        A través de las cartas del tarot, podemos explorar tus creencias y actitudes sobre el dinero y
                        descubrir nuevos caminos para mejorar tus finanzas. Podemos ayudarte a identificar tus
                        fortalezas y debilidades financieras, y descubrir nuevas formas de atraer la abundancia y la
                        prosperidad a tu vida.
                    </p>
                </div>
            </div>
            <img
                src={require('../../../assets/images/homepage/moneyu.jpg')}
                alt=""
                className={props.isVisible ? styles.img_love + ' ' + styles.show : styles.img_love}
            />
        </div>
    )
}

export default Money
