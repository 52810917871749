export interface HexagramaType {
    diagrama: string
    nombre: string
    numero: number
}

export const hexagramas: HexagramaType[] = [
    {
        diagrama: '111111',
        nombre: 'Qian',
        numero: 1,
    },
    {
        diagrama: '000000',
        nombre: 'Kun',
        numero: 2,
    },
    {
        diagrama: '010001',
        nombre: 'Zhun',
        numero: 3,
    },
    {
        diagrama: '100010',
        nombre: 'Meng',
        numero: 4,
    },
    {
        diagrama: '010111',
        nombre: 'Hsü',
        numero: 5,
    },
    {
        diagrama: '111010',
        nombre: 'Sung',
        numero: 6,
    },
    {
        diagrama: '000010',
        nombre: 'Shih',
        numero: 7,
    },
    {
        diagrama: '010000',
        nombre: 'Pi',
        numero: 8,
    },
    {
        diagrama: '110111',
        nombre: "Hsiao-Ch'u",
        numero: 9,
    },
    {
        diagrama: '111011',
        nombre: 'Lü',
        numero: 10,
    },
    {
        diagrama: '000111',
        nombre: "T'ai",
        numero: 11,
    },
    {
        diagrama: '111000',
        nombre: 'Pi',
        numero: 12,
    },
    {
        diagrama: '111101',
        nombre: "T'Ung-Jen",
        numero: 13,
    },
    {
        diagrama: '101111',
        nombre: 'Ta-yu',
        numero: 14,
    },
    {
        diagrama: '000100',
        nombre: "Ch'ien",
        numero: 15,
    },
    {
        diagrama: '001000',
        nombre: 'Yü',
        numero: 16,
    },
    {
        diagrama: '011001',
        nombre: 'Sul',
        numero: 17,
    },
    {
        diagrama: '100110',
        nombre: 'Ku',
        numero: 18,
    },
    {
        diagrama: '000011',
        nombre: 'Lin',
        numero: 19,
    },
    {
        diagrama: '110000',
        nombre: 'Kuan',
        numero: 20,
    },
    {
        diagrama: '101001',
        nombre: 'Shih-Ho',
        numero: 21,
    },
    {
        diagrama: '100101',
        nombre: 'Pi',
        numero: 22,
    },
    {
        diagrama: '100000',
        nombre: 'Po',
        numero: 23,
    },
    {
        diagrama: '000001',
        nombre: 'Fu',
        numero: 24,
    },
    {
        diagrama: '111001',
        nombre: 'Wu-Wang',
        numero: 25,
    },
    {
        diagrama: '100111',
        nombre: 'Ta-Chú',
        numero: 26,
    },
    {
        diagrama: '100001',
        nombre: 'I',
        numero: 27,
    },
    {
        diagrama: '011110',
        nombre: 'Ta-Kuo',
        numero: 28,
    },
    {
        diagrama: '010010',
        nombre: "K'an",
        numero: 29,
    },
    {
        diagrama: '101101',
        nombre: 'Li',
        numero: 30,
    },
    {
        diagrama: '011100',
        nombre: 'Hsien',
        numero: 31,
    },
    {
        diagrama: '001110',
        nombre: 'Heng',
        numero: 32,
    },
    {
        diagrama: '111100',
        nombre: 'Tun',
        numero: 33,
    },
    {
        diagrama: '001111',
        nombre: 'Ta-Chuang',
        numero: 34,
    },
    {
        diagrama: '101000',
        nombre: 'Chin',
        numero: 35,
    },
    {
        diagrama: '000101',
        nombre: 'Ming-I',
        numero: 36,
    },
    {
        diagrama: '110101',
        nombre: 'Chia-Jen',
        numero: 37,
    },
    {
        diagrama: '101011',
        nombre: 'Kuei',
        numero: 38,
    },
    {
        diagrama: '010100',
        nombre: 'Chien',
        numero: 39,
    },
    {
        diagrama: '001010',
        nombre: 'Hsieh',
        numero: 40,
    },
    {
        diagrama: '100011',
        nombre: 'Sun',
        numero: 41,
    },
    {
        diagrama: '110001',
        nombre: 'I',
        numero: 42,
    },
    {
        diagrama: '011111',
        nombre: 'Kual',
        numero: 43,
    },
    {
        diagrama: '111110',
        nombre: 'Kou',
        numero: 44,
    },
    {
        diagrama: '011000',
        nombre: "T'sui",
        numero: 45,
    },
    {
        diagrama: '000110',
        nombre: 'Sheng',
        numero: 46,
    },
    {
        diagrama: '011010',
        nombre: "K'un",
        numero: 47,
    },
    {
        diagrama: '010110',
        nombre: 'Ching',
        numero: 48,
    },
    {
        diagrama: '011101',
        nombre: 'Ko',
        numero: 49,
    },
    {
        diagrama: '101110',
        nombre: 'Ting',
        numero: 50,
    },
    {
        diagrama: '001001',
        nombre: 'Chen',
        numero: 51,
    },
    {
        diagrama: '100100',
        nombre: 'Ken',
        numero: 52,
    },
    {
        diagrama: '110100',
        nombre: 'Chien',
        numero: 53,
    },
    {
        diagrama: '001011',
        nombre: 'Kuei-Mei',
        numero: 54,
    },
    {
        diagrama: '001101',
        nombre: 'Feng',
        numero: 55,
    },
    {
        diagrama: '101100',
        nombre: 'Lü',
        numero: 56,
    },
    {
        diagrama: '110110',
        nombre: 'Sun',
        numero: 57,
    },
    {
        diagrama: '011011',
        nombre: 'Tui',
        numero: 58,
    },
    {
        diagrama: '110010',
        nombre: 'Huan',
        numero: 59,
    },
    {
        diagrama: '010011',
        nombre: 'Shieh',
        numero: 60,
    },
    {
        diagrama: '110011',
        nombre: 'Chung-Fu',
        numero: 61,
    },
    {
        diagrama: '001100',
        nombre: 'Hsiao-Kuo',
        numero: 62,
    },
    {
        diagrama: '010101',
        nombre: 'Chi-Chi',
        numero: 63,
    },
    {
        diagrama: '101010',
        nombre: 'Wei-Chi',
        numero: 64,
    },
]
