import Swal from "sweetalert2";
import { AllCards, AllCardsType } from "../../data/all-cards";
import {
  ERROR,
  ERROR_LOGIN,
  GET_ALL_CARDS,
  GET_ALL_CONSULTS,
  LOGIN,
  LOGOUT,
  RESET_CONSULT,
  RESET_ERROR,
  SAVE_ALL_CARDS,
  SAVE_CONSULT,
} from "../constants";

interface InitialStateType {
  allCards: AllCardsType[];
  user: any;
  errorLogin: number;
  consults: [];
  consult: any;
}

const initialState: InitialStateType = {
  allCards: AllCards,
  user: {},
  errorLogin: 0,
  consults: [],
  consult: {},
};

const Reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_ALL_CARDS: {
      return {
        ...state,
        allCards: action.payload,
      };
    }
    case SAVE_ALL_CARDS: {
      return {
        ...state,
        allCards: action.payload,
      };
    }
    case LOGIN: {
      return {
        ...state,
        user: action.payload,
        errorLogin: 0,
      };
    }
    case ERROR: {
      Swal.fire("Error", action.payload, "error");
      return state;
    }
    case ERROR_LOGIN: {
      return {
        ...state,
        errorLogin: state.errorLogin + 1,
      };
    }
    case RESET_ERROR: {
      return {
        ...state,
        errorLogin: 0,
      };
    }
    case LOGOUT: {
      return {
        ...state,
        user: {},
      };
    }
    case SAVE_CONSULT: {
      return {
        ...state,
        consult: action.payload,
      };
    }
    case RESET_CONSULT: {
      return {
        ...state,
        consult: {},
      };
    }
    case GET_ALL_CONSULTS: {
      return {
        ...state,
        consults: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default Reducer;
