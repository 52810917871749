export const phrases: string[] = [
    'LIBERA TU POTENCIAL',
    'ENCUENTRA TU DESTINO',
    'DESCUBRE TU MAGIA',
    'VIVE POSITIVAMENTE',
    'APRENDE A SANARTE',
    'CREE EN TI Y TODO SERA POSIBLE',
    'SI PUEDES SOÑARLO, PUEDES LOGRARLO',
    'TU ACTITUD DETERMINA TUS RESULTADOS',
    'LOS LIMITES SOLO EXISTEN EN TU MENTE',
    'SI NO OCURRE, TAMBIÉN ES UNA SEÑAL',
    'PREOCUPATE MENOS Y SONRIE MÁS',
]
