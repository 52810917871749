import React from 'react'
import { AllCardsType } from '../../../data/all-cards'

export const ShowCards = ({ selectedCards }: { selectedCards: AllCardsType[] }) => {
    return (
        <>
            <div className="w-full flex flex-row mini:gap-1 xl:gap-5 mini:justify-center xl:justify-between mini:px-2 xl:px-5">
                <div className="flex flex-col gap-2">
                    <img
                        src={require('../../../assets/cards/' + selectedCards[0].url + '/' + selectedCards[0].image)}
                        className={`mini:w-[17vw] xl:w-[7vw] mini:h-auto xl:h-[26vh] rounded-md ${selectedCards[0].position === "invertida" ? "rotate-180" : ""}`}
                        alt=""
                    />
                    <div className="mini:w-[17vw] xl:w-[7vw] bg-violetLight text-sm text-center rounded-md">
                        {selectedCards[0].name}
                    </div>
                </div>
                <div className="flex flex-col gap-2">
                    <img
                        src={require('../../../assets/cards/' + selectedCards[1].url + '/' + selectedCards[1].image)}
                        className={`mini:w-[17vw] xl:w-[7vw] mini:h-auto xl:h-[26vh] rounded-md ${selectedCards[1].position === "invertida" ? "rotate-180" : ""}`}
                        alt=""
                    />
                    <div className="mini:w-[17vw] xl:w-[7vw] bg-violetLight text-sm text-center rounded-md">
                        {selectedCards[1].name}
                    </div>
                </div>
                <div className="flex flex-col gap-2">
                    <img
                        src={require('../../../assets/cards/' + selectedCards[2].url + '/' + selectedCards[2].image)}
                        className={`mini:w-[17vw] xl:w-[7vw] mini:h-auto xl:h-[26vh] rounded-md ${selectedCards[2].position === "invertida" ? "rotate-180" : ""}`}
                        alt=""
                    />
                    <div className="mini:w-[17vw] xl:w-[7vw] bg-violetLight text-sm text-center rounded-md">
                        {selectedCards[2].name}
                    </div>
                </div>
                <div className="flex flex-col gap-2">
                    <img
                        src={require('../../../assets/cards/' + selectedCards[3].url + '/' + selectedCards[3].image)}
                        className={`mini:w-[17vw] xl:w-[7vw] mini:h-auto xl:h-[26vh] rounded-md ${selectedCards[3].position === "invertida" ? "rotate-180" : ""}`}
                        alt=""
                    />
                    <div className="mini:w-[17vw] xl:w-[7vw] bg-violetLight text-sm text-center rounded-md">
                        {selectedCards[3].name}
                    </div>
                </div>
                <div className="flex flex-col gap-2">
                    <img
                        src={require('../../../assets/cards/' + selectedCards[4].url + '/' + selectedCards[4].image)}
                        className={`mini:w-[17vw] xl:w-[7vw] mini:h-auto xl:h-[26vh] rounded-md ${selectedCards[4].position === "invertida" ? "rotate-180" : ""}`}
                        alt=""
                    />
                    <div className="mini:w-[17vw] xl:w-[7vw] bg-violetLight text-sm text-center rounded-md">
                        {selectedCards[4].name}
                    </div>
                </div>
            </div>

            <div className="w-full flex flex-row mini: gap-1 xl:gap-5 mini:justify-center xl:justify-between mini:px-2 xl:px-5 pt-8">
                <div className="flex flex-col gap-2">
                    <img
                        src={require('../../../assets/cards/' + selectedCards[5].url + '/' + selectedCards[5].image)}
                        className={`mini:w-[17vw] xl:w-[7vw] mini:h-auto xl:h-[26vh] rounded-md ${selectedCards[5].position === "invertida" ? "rotate-180" : ""}`}
                        alt=""
                    />
                    <div className="mini:w-[17vw] xl:w-[7vw] bg-violetLight text-sm text-center rounded-md">
                        {selectedCards[5].name}
                    </div>
                </div>
                <div className="flex flex-col gap-2">
                    <img
                        src={require('../../../assets/cards/' + selectedCards[6].url + '/' + selectedCards[6].image)}
                        className={`mini:w-[17vw] xl:w-[7vw] mini:h-auto xl:h-[26vh] rounded-md ${selectedCards[6].position === "invertida" ? "rotate-180" : ""}`}
                        alt=""
                    />
                    <div className="mini:w-[17vw] xl:w-[7vw] bg-violetLight text-sm text-center rounded-md">
                        {selectedCards[6].name}
                    </div>
                </div>
                <div className="flex flex-col gap-2">
                    <img
                        src={require('../../../assets/cards/' + selectedCards[7].url + '/' + selectedCards[7].image)}
                        className={`mini:w-[17vw] xl:w-[7vw] mini:h-auto xl:h-[26vh] rounded-md ${selectedCards[7].position === "invertida" ? "rotate-180" : ""}`}
                        alt=""
                    />
                    <div className="mini:w-[17vw] xl:w-[7vw] bg-violetLight text-sm text-center rounded-md">
                        {selectedCards[7].name}
                    </div>
                </div>
                <div className="flex flex-col gap-2">
                    <img
                        src={require('../../../assets/cards/' + selectedCards[8].url + '/' + selectedCards[8].image)}
                        className={`mini:w-[17vw] xl:w-[7vw] mini:h-auto xl:h-[26vh] rounded-md ${selectedCards[8].position === "invertida" ? "rotate-180" : ""}`}
                        alt=""
                    />
                    <div className="mini:w-[17vw] xl:w-[7vw] bg-violetLight text-sm text-center rounded-md">
                        {selectedCards[8].name}
                    </div>
                </div>
                <div className="flex flex-col gap-2">
                    <img
                        src={require('../../../assets/cards/' + selectedCards[9].url + '/' + selectedCards[9].image)}
                        className={`mini:w-[17vw] xl:w-[7vw] mini:h-auto xl:h-[26vh] rounded-md ${selectedCards[9].position === "invertida" ? "rotate-180" : ""}`}
                        alt=""
                    />
                    <div className="mini:w-[17vw] xl:w-[7vw] bg-violetLight text-sm text-center rounded-md">
                        {selectedCards[9].name}
                    </div>
                </div>
            </div>
        </>
    )
}
