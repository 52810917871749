import React, { useEffect, useState } from "react";
import styles from "./consults-view.module.css";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ConsultsType } from "../../../interfaces/utils";
import { getCard, isEmpty } from "../../../utils/functions";
import { AllCards, AllCardsType } from "../../../data/all-cards";
import { GetAllCards } from "../../../store/actions";
import Card from "../../../components/card/card";
import { Dispatch } from "redux";

const ConsultsView: React.FC = (): JSX.Element => {
  const dispatch: Dispatch<any> = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const { consults, user, allCards } = useSelector((state: any) => state);
  const [consult, setConsult]: any = useState();

  useEffect(() => {
    if (id) {
      let position = consults.findIndex(
        (consult: ConsultsType) => consult.objectId === id
      );
      setConsult(consults[position]);
    } else {
      navigate("/");
    }
    if (isEmpty(user)) {
      navigate("/");
    }
    if (isEmpty(allCards)) {
      dispatch(GetAllCards());
    }
  }, [consults, user, id, navigate, dispatch, allCards]);

  const getType = (value: string): string => {
    let type;
    if (value === "general") {
      type = "General";
    } else if (value === "amor") {
      type = "Amor";
    } else {
      type = "Cinco";
    }
    return type;
  };

  return (
    <div className={styles.body}>
      <div className={styles.title_container}>
        <button className={styles.button_back} onClick={() => navigate(-1)}>
          <img
            src={require("../../../assets/images/arrow_left.png")}
            alt=""
            className={styles.back}
          />
          Back
        </button>
        <span className={styles.title}>Detalle de la consulta</span>
      </div>
      <div className={styles.data_container}>
        <div className={styles.data_header}>
          <span className={styles.data_title}>Código</span>
          <span className={styles.data_title}>Tipo</span>
          <span className={styles.data_title}>Fecha</span>
          <span className={styles.data_title}>Hora</span>
          <span className={styles.data_title}>Consultor</span>
          <span className={styles.data_title}>Consultante</span>
          <span className={styles.data_title}>Consulta</span>
          <div className={styles.data + " " + styles.center}>
            {consult?.objectId}
          </div>
          <div className={styles.data}>{getType(consult?.type)}</div>
          <div className={styles.data + " " + styles.center}>
            {new Date(consult?.createdAt).toLocaleDateString()}
          </div>
          <div className={styles.data + " " + styles.center}>
            {new Date(consult?.createdAt).toLocaleTimeString()}
          </div>
          <div className={styles.data}>{consult?.user}</div>
          <div className={styles.data}>{consult?.clientName}</div>
          <div className={styles.data}>{consult?.clientAnswer}</div>
        </div>
        <div className={styles.info}>
          <div className={styles.left}>
            <div className={styles.info_title}>Cartas</div>
            {consult?.type !== "general" ? (
              <div className={styles.cards_container}>
                {!isEmpty(allCards) &&
                  consult?.cards.map((card: AllCardsType) => (
                    <Card card={card} />
                  ))}
              </div>
            ) : (
              <div className={styles.cards_container_general}>
                <div className={styles.signo}>
                  <div className={styles.icon_container}>
                    <img
                      src={require("../../../assets/images/general/aries.png")}
                      alt=""
                      className={styles.signo_icon}
                    />
                  </div>
                  <span className={styles.signo_text}>Aries</span>
                </div>
                <Card card={consult.cards[0]} />
                <Card card={consult.cards[1]} />
                <Card card={consult.cards[2]} />

                <div className={styles.signo}>
                  <div className={styles.icon_container}>
                    <img
                      src={require("../../../assets/images/general/tauro.png")}
                      alt=""
                      className={styles.signo_icon}
                    />
                  </div>
                  <span className={styles.signo_text}>Tauro</span>
                </div>
                <Card card={consult.cards[3]} />
                <Card card={consult.cards[4]} />
                <Card card={consult.cards[5]} />

                <div className={styles.signo}>
                  <div className={styles.icon_container}>
                    <img
                      src={require("../../../assets/images/general/geminis.png")}
                      alt=""
                      className={styles.signo_icon}
                    />
                  </div>
                  <span className={styles.signo_text}>Gemínis</span>
                </div>
                <Card card={consult.cards[6]} />
                <Card card={consult.cards[7]} />
                <Card card={consult.cards[8]} />

                <div className={styles.signo}>
                  <div className={styles.icon_container}>
                    <img
                      src={require("../../../assets/images/general/cancer.png")}
                      alt=""
                      className={styles.signo_icon}
                    />
                  </div>
                  <span className={styles.signo_text}>Cáncer</span>
                </div>
                <Card card={consult.cards[9]} />
                <Card card={consult.cards[10]} />
                <Card card={consult.cards[11]} />

                <div className={styles.signo}>
                  <div className={styles.icon_container}>
                    <img
                      src={require("../../../assets/images/general/leo.png")}
                      alt=""
                      className={styles.signo_icon}
                    />
                  </div>
                  <span className={styles.signo_text}>Leo</span>
                </div>
                <Card card={consult.cards[12]} />
                <Card card={consult.cards[13]} />
                <Card card={consult.cards[14]} />

                <div className={styles.signo}>
                  <div className={styles.icon_container}>
                    <img
                      src={require("../../../assets/images/general/virgo.png")}
                      alt=""
                      className={styles.signo_icon}
                    />
                  </div>
                  <span className={styles.signo_text}>Virgo</span>
                </div>
                <Card card={consult.cards[15]} />
                <Card card={consult.cards[16]} />
                <Card card={consult.cards[17]} />

                <div className={styles.signo}>
                  <div className={styles.icon_container}>
                    <img
                      src={require("../../../assets/images/general/libra.png")}
                      alt=""
                      className={styles.signo_icon}
                    />
                  </div>
                  <span className={styles.signo_text}>Libra</span>
                </div>
                <Card card={consult.cards[18]} />
                <Card card={consult.cards[19]} />
                <Card card={consult.cards[20]} />

                <div className={styles.signo}>
                  <div className={styles.icon_container}>
                    <img
                      src={require("../../../assets/images/general/escorpio.png")}
                      alt=""
                      className={styles.signo_icon}
                    />
                  </div>
                  <span className={styles.signo_text}>Escorpio</span>
                </div>
                <Card card={consult.cards[21]} />
                <Card card={consult.cards[22]} />
                <Card card={consult.cards[23]} />

                <div className={styles.signo}>
                  <div className={styles.icon_container}>
                    <img
                      src={require("../../../assets/images/general/sagitario.png")}
                      alt=""
                      className={styles.signo_icon}
                    />
                  </div>
                  <span className={styles.signo_text}>Sagitario</span>
                </div>
                <Card card={consult.cards[24]} />
                <Card card={consult.cards[25]} />
                <Card card={consult.cards[26]} />

                <div className={styles.signo}>
                  <div className={styles.icon_container}>
                    <img
                      src={require("../../../assets/images/general/capricornio.png")}
                      alt=""
                      className={styles.signo_icon}
                    />
                  </div>
                  <span className={styles.signo_text}>Capricornio</span>
                </div>
                <Card card={consult.cards[27]} />
                <Card card={consult.cards[28]} />
                <Card card={consult.cards[29]} />

                <div className={styles.signo}>
                  <div className={styles.icon_container}>
                    <img
                      src={require("../../../assets/images/general/acuario.png")}
                      alt=""
                      className={styles.signo_icon}
                    />
                  </div>
                  <span className={styles.signo_text}>Acuario</span>
                </div>
                <Card card={consult.cards[30]} />
                <Card card={consult.cards[31]} />
                <Card card={consult.cards[32]} />

                <div className={styles.signo}>
                  <div className={styles.icon_container}>
                    <img
                      src={require("../../../assets/images/general/piscis.png")}
                      alt=""
                      className={styles.signo_icon}
                    />
                  </div>
                  <span className={styles.signo_text}>Piscis</span>
                </div>
                <Card card={consult.cards[33]} />
                <Card card={consult.cards[34]} />
                <Card card={consult.cards[35]} />
              </div>
            )}
          </div>
          <div className={styles.right}>
            <div className={styles.info_title}>Respuesta</div>
            <div className={styles.response}>
              <textarea
                name="response"
                value={consult?.response}
                className={styles.textarea}
                readOnly
              ></textarea>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConsultsView;
