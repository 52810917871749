import { AllCardsType } from '../../../data/all-cards'
import { DataType, DataTypeVidaPasada } from '../../../interfaces/utils'

export const interpret = (cards: AllCardsType[], data: DataTypeVidaPasada, user: string, type: string) => {
    let consult = `Por favor realiza la interpretación de la tirada de Vida Pasada los más detallada y extensa posible que realizaste para ${
        data.consultante.name
    }, teniendo en cuenta su signo de ${
        data.consultante.zodiac
    } y teniendo en cuenta también los significados de las combinaciones de las cartas, con la siguiente distribuición: ${
        cards[0].name
    }${cards[0].position === "invertida" ? " invertida" : ""}, ${cards[1].name}${cards[1].position === "invertida" ? " invertida" : ""}, ${cards[2].name}${cards[2].position === "invertida" ? " invertida" : ""}, ${cards[3].name}${cards[3].position === "invertida" ? " invertida" : ""}, ${cards[4].name}${cards[4].position === "invertida" ? " invertida" : ""}, ${cards[5].name}${cards[5].position === "invertida" ? " invertida" : ""} y ${
        cards[6].name
    }${cards[6].position === "invertida" ? " invertida" : ""} en la identidad en la vida pasada (trata de definir de que sexo y que tipo de persona era, a que se dedicaba, si tenia estudios o no, estado civil, hijos y todo lo que más puedas extraer de estas cartas), ${
        cards[7].name
    }${cards[7].position === "invertida" ? " invertida" : ""} y ${cards[8].name}${cards[8].position === "invertida" ? " invertida" : ""} en ambiente y circunstacias, ${cards[9].name}${cards[9].position === "invertida" ? " invertida" : ""} y ${cards[10].name}${cards[10].position === "invertida" ? " invertida" : ""} en relaciones claves, ${
        cards[11].name
    }${cards[11].position === "invertida" ? " invertida" : ""} y ${cards[12].name}${cards[12].position === "invertida" ? " invertida" : ""} en desafíos principales, ${cards[13].name}${cards[13].position === "invertida" ? " invertida" : ""} y ${cards[14].name}${cards[14].position === "invertida" ? " invertida" : ""} en logros y éxitos, ${
        cards[15].name
    }${cards[15].position === "invertida" ? " invertida" : ""} y ${cards[16].name}${cards[16].position === "invertida" ? " invertida" : ""} en lección principal aprendida, ${cards[17].name}${cards[17].position === "invertida" ? " invertida" : ""} y ${
        cards[18].name
    }${cards[18].position === "invertida" ? " invertida" : ""} en conexión de tu vida pasada con la vida actual,  ${cards[19].name}${cards[19].position === "invertida" ? " invertida" : ""} y ${
        cards[20].name
    }${cards[20].position === "invertida" ? " invertida" : ""} en patrones Kármicos recurrentes (Patrones de comportamiento o situaciones que se repiten en tu vida actual debido a esa vida pasada), ${
        cards[21].name
    }${cards[21].position === "invertida" ? " invertida" : ""} y ${
        cards[22].name
    }${cards[22].position === "invertida" ? " invertida" : ""} en sanación y liberación (lo que necesitas sanar de esa vida pasada para avanzar en la vida presente), ${
        cards[23].name
    }${cards[23].position === "invertida" ? " invertida" : ""} y ${cards[24].name}${cards[24].position === "invertida" ? " invertida" : ""} en consejo para el futuro. ${
        data.consulta.length > 0 ? 'Ten en cuenta en toda esta lectura lo siguiente:' + data.consulta + '.' : ''
    } Dirígete directamente al consultante, no utilices la tercera persona refiriendote a la consultante, aún cuando las consultas se encuentren en tercera persona, adapta la respuesta para que quede en segunda persona. Incluye después de la interpretación de cada una de todo el desarrollo de la interpretación, en un nuevo párrafo, un resúmen que aclare concretamente lo que las cartas le quieren transmitir al consultante en esta tirada.`

    let info = {
        consult: consult,
        user: user,
        clientName: data.consultante.name,
        clientAnswer: data.consulta,
        cards: cards,
        type: type,
    }

    return info
}
