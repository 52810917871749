import { AllCardsType } from '../../../data/all-cards'
import { HexagramaType } from '../../../data/hexagramas'
import { DataTypePregunta } from '../../../interfaces/utils'

export const interpret = (
    principal: any,
    complementario: HexagramaType | null,
    data: DataTypePregunta,
    user: string,
    type: string
) => {
    let consult = `En la tirada de monedas para el I-Ching que realizaste para ${
        data.consultante.name
    } que necesita saber sobre lo siguiente: ${
        data.consulta
    }, me puedes proporcionar la respuesta que el consultante necesita con un hexagrama principal número ${
        principal[7]
    }, conformado con primer línea: ${principal[0]}, segunda línea: ${principal[1]}, tercera línea: ${
        principal[2]
    }, cuarta línea: ${principal[3]}, quinta línea: ${principal[4]} y sexta línea: ${principal[5]}${
        complementario !== null ? ' y un hexagrama complementario' + complementario.numero + '.' : '.'
    } Si puedes, ten el cuenta el signo: ${data.consultante.zodiac} del consultante.`

    let info = {
        consult: consult,
        user: user,
        clientName: data.consultante.name,
        clientAnswer: data.consulta,
        cards: [principal[7], complementario?.numero],
        type: type,
    }

    return info
}
