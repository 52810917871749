export const English = {
  translation: {
    name: "Name",
    throw_of_yes_or_no: "Throw of yes or no",
    how_it_works: "How it Works",
    about_tarot: "About Tarot",
    consults: "Consults",
    clients: "Clients",
    contact: "Contact",
    ask: "Ask",
    done: "Done",
    shuffle: "Shuffle",
    interpret: "Interpret",
    edit: "Edit",
    new_consult: "New Consult",
    new_client: "New Client",
    see_text: "See Text",
    return: "Return",
  },
};
