import React, { useState } from "react";
import "./manual-tres.css";
import { ArcanosMayores } from "../../../data/data";
import { ArcanosMayores2Type } from "../../../interfaces/arcanos-mayores";
import SidebarAnswers from "./sidebar-answers/sidebar-answers";
import ResultManualTres from "./result-manual-tres/result-manual-tres";

const ManualTres = () => {
  const mayores: ArcanosMayores2Type[] = ArcanosMayores;
  const [pos, setPos]: any = useState([]);
  const [answer, setAnswer]: any = useState("");
  const [consult, setConsult]: any = useState(false);

  const handleSelect = (value: number): void => {
    const position = pos.findIndex((elem: any) => elem.card === value);
    // const position = pos.indexOf(value)
    if (position !== -1) {
      setPos(pos.filter((item: any) => item.card !== value));
      // setPos(pos.filter((item: number) => item !== value))
    } else {
      if (pos.length < 3) {
        setPos([...pos, { card: value, position: "derecha" }]);
      }
    }
  };

  const handlePosition = (value: number): void => {
    const position = pos.findIndex((elem: any) => elem.card === value);
    const posi = pos[position].position;
    if (posi === "derecha") {
      setPos(
        pos.map((elem: any, index: number) => {
          if (index === position) {
            return {
              card: elem.card,
              position: "invertida",
            };
          } else {
            return elem;
          }
        })
      );
    } else {
      setPos(
        pos.map((elem: any, index: number) => {
          if (index === position) {
            return {
              card: elem.card,
              position: "derecha",
            };
          } else {
            return elem;
          }
        })
      );
    }
  };

  const handleAnswer = (value: string): void => {
    setAnswer(value);
  };

  const handleConsult = (value: boolean): void => {
    setConsult(value);
  };

  const handleReset = (): void => {
    setPos([]);
    setAnswer("");
    setConsult(false);
  };

  return (
    <div className="body">
      <SidebarAnswers
        setAnswer={handleAnswer}
        setConsult={handleConsult}
        reset={handleReset}
        answer={answer}
        selects={pos.length}
        consult={consult}
      />
      {!consult ? (
        <div className="manualTres-body">
          <div className="cards-container">
            {mayores?.map((card, index) => (
              <div className="card-container" key={index}>
                <img
                  src={require("../../../assets/cards/mayores/" + card.image)}
                  alt=""
                  className={
                    pos.findIndex(
                      (elem: any) =>
                        elem.card === card.codigo &&
                        elem.position === "invertida"
                    ) !== -1
                      ? "card invertida"
                      : "card"
                  }
                  onClick={() => handleSelect(card.codigo)}
                />
                <span className="name">{card.name}</span>

                <div
                  className={
                    pos.findIndex((elem: any) => elem.card === card.codigo) !==
                    -1
                      ? "invert selected"
                      : "invert"
                  }
                  onClick={() => handlePosition(card.codigo)}
                >
                  <div className="invert-content">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      viewBox="0 60 600 600"
                    >
                      <path
                        d="M196 725q-20-36-28-72.5t-8-74.5q0-131 94.5-225.5T480 258h43l-80-80 39-39 149 149-149 149-40-40 79-79h-41q-107 0-183.5 76.5T220 578q0 29 5.5 55t13.5 49l-43 43Zm280 291L327 867l149-149 39 39-80 80h45q107 0 183.5-76.5T740 577q0-29-5-55t-15-49l43-43q20 36 28.5 72.5T800 577q0 131-94.5 225.5T480 897h-45l80 80-39 39Z"
                        fill="#ffffff"
                        transform="scale(0.625)"
                      />
                    </svg>
                  </div>
                </div>

                <div
                  className={
                    pos.findIndex((elem: any) => elem.card === card.codigo) !==
                    -1
                      ? "select select-selected"
                      : "select"
                  }
                >
                  <span>Seleccionada</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <ResultManualTres cards={pos} reset={handleReset} answer={answer} />
      )}
    </div>
  );
};

export default ManualTres;
