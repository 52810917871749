import React, { useEffect, useState } from 'react'
import { ButtonNormal } from '../../../components/buttons/button-normal'
import { DataType } from '../../../interfaces/utils'
import { GetPersonalidad, copyResponse, getZodiac, isEmpty } from '../../../utils/functions'
import { useDispatch, useSelector } from 'react-redux'
import { interpret } from './functions'
import { GetConsult } from '../../../store/actions'
import { Dispatch } from 'redux'
import { CompatibilidadType, Zodiaco, ZodiacoType } from '../../../data/zodiaco'

const Compatibilidad = () => {
    const [showResponse, setShowResponse] = useState<boolean>(false)
    const [response, setResponse] = useState('')
    const dispatch: Dispatch<any> = useDispatch()
    const { user, consult } = useSelector((state: any) => state)
    const [data, setData] = useState<DataType>({
        consultante: {
            name: '',
            date: '',
            zodiac: '',
            personality: '',
        },
        consultado: {
            name: '',
            date: '',
            zodiac: '',
            personality: '',
        },
        consulta: '',
    })
    const [status, setStatus] = useState({
        isDone: false,
        isConsulting: false,
        isInterpret: false,
    })
    const zodiaco: ZodiacoType[] = Zodiaco

    const handleZodiacConsultant = (date: string): void => {
        let zodiac = getZodiac(date)
        let personality = GetPersonalidad(date)
        setData({
            ...data,
            consultante: {
                ...data.consultante,
                zodiac,
                date,
                personality,
            },
        })
    }

    const handleZodiacThird = (date: string): void => {
        let zodiac = getZodiac(date)
        let personality = GetPersonalidad(date)
        setData({
            ...data,
            consultado: {
                ...data.consultado,
                zodiac,
                date,
                personality,
            },
        })
    }

    const handleInputConsultant = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (event.target.name === 'date' && parseInt(event.target.value.slice(0, 4)) > 1900) {
            handleZodiacConsultant(event.target.value)
        } else {
            setData({
                ...data,
                consultante: {
                    ...data.consultante,
                    [event.target.name]: event.target.value,
                },
            })
        }
    }

    const handleInputThird = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (event.target.name === 'date' && parseInt(event.target.value.slice(0, 4)) > 1900) {
            handleZodiacThird(event.target.value)
        } else {
            setData({
                ...data,
                consultado: {
                    ...data.consultado,
                    [event.target.name]: event.target.value,
                },
            })
        }
    }

    // MANEJA LOS ESTADOS
    const handleStatus = (name: string, value: boolean): void => {
        setStatus({
            ...status,
            [name]: value,
        })
    }

    const interpretar = () => {
        handleStatus('isConsulting', true)
        const info = interpret(data, user.username, 'compatibilidad')
        dispatch(GetConsult(info))
        //handleStatus('isConsulting', false)
        //setShowResponse(true)
    }

    useEffect(() => {
        setStatus({
            isConsulting: false,
            isInterpret: false,
            isDone: false,
        })
        setShowResponse(false)
        setResponse('')
        let name_consultante = localStorage.getItem('name_consultante')
        if (name_consultante) {
            data.consultante.name = name_consultante
            data.consultante.date = localStorage.getItem('date_consultante') || ''
            data.consultante.zodiac = localStorage.getItem('zodiac_consultante') || ''
            data.consultado.name = localStorage.getItem('name_consultado') || ''
            data.consultado.date = localStorage.getItem('date_consultado') || ''
            data.consultado.zodiac = localStorage.getItem('zodiac_consultado') || ''

            localStorage.removeItem('name_consultante')
            localStorage.removeItem('date_consultante')
            localStorage.removeItem('zodiac_consultante')
            localStorage.removeItem('name_consultado')
            localStorage.removeItem('date_consultado')
            localStorage.removeItem('zodiac_consultado')

            data.consultante.personality = GetPersonalidad(data.consultante.date)
            data.consultado.personality = GetPersonalidad(data.consultado.date)
        }
    }, [])

    useEffect(() => {
        if (showResponse) {
            let signoConsultante = document.getElementById('consultante')
            if (signoConsultante && data.consultante.zodiac.length > 0) {
                let description = zodiaco.filter((signo) => signo.name === data.consultante.zodiac)
                signoConsultante.innerHTML = description[0].description
            }
            if (data.consultado.zodiac.length > 0) {
                let signoTercero = document.getElementById('tercero')
                if (signoTercero) {
                    let description = zodiaco.filter((signo) => signo.name === data.consultado.zodiac)
                    signoTercero.innerHTML = description[0].description
                }
            }
        }
    }, [showResponse, data, zodiaco])

    useEffect(() => {
        if (!isEmpty(consult) && status.isConsulting) {
            setStatus({
                ...status,
                isConsulting: false,
                isInterpret: true,
            })
            setResponse(consult.response)
            setShowResponse(true)
        }
    }, [consult])

    // LIMPIA LOS ESTADOS PARA ATENDER UN NUEVO CLIENTE
    const nuevoCliente = () => {
        setResponse('')
        setStatus({
            ...status,
            isDone: false,
            isInterpret: false,
        })
        setData({
            consultante: {
                name: '',
                date: '',
                zodiac: '',
                personality: '',
            },
            consultado: {
                name: '',
                date: '',
                zodiac: '',
                personality: '',
            },
            consulta: '',
        })
        setShowResponse(false)
    }

    return (
        <div className="w-full mini:min-h-screen mini:h-full xl:h-height-main bg-comp bg-no-repeat bg-cover mini:px-2 md:px-4 relative mini:pb-10 xl:pb-0">
            <p className="mini:text-xl xl:text-4xl font-bold text-white italic w-full text-center py-5">
                Compatibilidad de Dos Personas
            </p>
            <div className="flex mini:flex-col xl:flex-row w-full">
                {/* left */}
                <div className="mini:w-full xl:w-1/2 bg-violetLight h-full rounded-lg mini:px-2 mini:py-5 md:p-5 flex flex-col gap-6 shadow-card-horizontal">
                    <div className="flex mini:flex-col md:flex-row gap-3">
                        <input
                            name="name"
                            type="text"
                            value={data.consultante.name}
                            onChange={(e) => handleInputConsultant(e)}
                            placeholder="Nombre del Consultante"
                            className="h-10 mini:w-full md:w-1/2 pl-3 rounded-lg text-base text-gray-400 focus-visible:outline-none focus-visible:border-violetDark focus-visible:border-2"
                            autoFocus={true}
                        />
                        <div className="flex gap-3">
                            <input
                                type="date"
                                name="date"
                                value={data.consultante.date}
                                onChange={(e) => handleInputConsultant(e)}
                                placeholder="Nacimiento"
                                className="pl-3 pr-1lh-10 w-1/2 rounded-lg text-base text-gray-400 focus-visible:outline-none focus-visible:border-violetDark focus-visible:border-2"
                            />
                            <input
                                type="text"
                                name="zodiac"
                                value={data.consultante.zodiac}
                                readOnly={true}
                                className="px-3 h-10 rounded-lg text-base text-gray-400 w-1/2 disabled:bg-white"
                                disabled={true}
                            />
                        </div>
                    </div>
                    <div className="flex mini:flex-col md:flex-row gap-3">
                        <input
                            name="name"
                            type="text"
                            value={data.consultado.name}
                            onChange={(e) => handleInputThird(e)}
                            placeholder="Nombre del Consultado"
                            className="h-10 mini:w-full md:w-1/2 pl-3 rounded-lg textr-base text-gray-400 focus-visible:outline-none focus-visible:border-violetDark focus-visible:border-2"
                        />
                        <div className="flex gap-3">
                            <input
                                type="date"
                                name="date"
                                value={data.consultado.date}
                                onChange={(e) => handleInputThird(e)}
                                placeholder="Nacimiento"
                                className="pl-3 pr-1 h-10 w-1/2 rounded-lg textr-base text-gray-400 focus-visible:outline-none focus-visible:border-violetDark focus-visible:border-2"
                            />
                            <input
                                type="text"
                                name="zodiac"
                                value={data.consultado.zodiac}
                                readOnly={true}
                                className="px-3 h-10 rounded-lg textr-base text-gray-400 w-1/2 disabled:bg-white"
                                disabled={true}
                            />
                        </div>
                    </div>
                    <div className="flex gap-3 justify-center flex-wrap disabled:hidden">
                        <ButtonNormal
                            caption="Hecho"
                            onClick={() => handleStatus('isDone', true)}
                            disabled={
                                status.isDone ||
                                data.consultante.name.length === 0 ||
                                data.consultante.zodiac.length === 0 ||
                                data.consultado.name.length === 0 ||
                                data.consultado.zodiac.length === 0
                            }
                        />
                        <ButtonNormal
                            caption={status.isConsulting ? 'Interpretando' : 'Interpretar'}
                            onClick={() => interpretar()}
                            disabled={status.isInterpret || !status.isDone}
                            loading={status.isConsulting}
                        />
                        <ButtonNormal
                            caption={status.isConsulting ? 'Reinterpretando' : 'Reinterpretar'}
                            onClick={() => interpretar()}
                            disabled={!status.isInterpret}
                            loading={status.isConsulting}
                        />
                        <ButtonNormal
                            caption="Ver Respuesta"
                            onClick={() => setShowResponse(true)}
                            disabled={response.length === 0}
                        />
                    </div>
                </div>
                {showResponse ? (
                    <div className="absolute w-[90vw] h-[90vh] top-[2%] left-[5%] bg-zinc-200 shadow-2xl flex flex-row gap-5 rounded-xl mini:px-2 mini:py-5 md:p-5">
                        <div className="mini:w-full xl:w-2/3 flex flex-col gap-3">
                            <textarea
                                className="w-full h-full resize-none rounded-lg mini:p-1 md:p-5"
                                value={response}
                                onChange={(e) => setResponse(e.target.value)}
                            />
                            <div className="flex flex-row flex-wrap justify-center gap-1">
                                <ButtonNormal caption="Volver" onClick={() => setShowResponse(false)} />
                                <ButtonNormal caption="Copiar Respuesta" onClick={() => copyResponse(response)} />
                                <ButtonNormal caption="Nuevo Cliente" onClick={() => nuevoCliente()} />
                            </div>
                        </div>
                        <div className="w-1/3 flex flex-col gap-3 mini:hidden xl:block">
                            <p className="font-semibold">Consultante: {data.consultante.zodiac}</p>
                            <div
                                className="w-full bg-zinc-50 p-5 rounded-lg text-sm overflow-y-auto"
                                id="consultante"
                            ></div>
                        </div>
                    </div>
                ) : null}
            </div>
        </div>
    )
}

export default Compatibilidad
