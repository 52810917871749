import { AllCardsType } from '../data/all-cards'
import { ArcanosMayores } from '../data/data'
import { ArcanosMayores2Type } from './arcanos-mayores'

export interface IIndexable {
    [key: string]: any
}

export const getIndexCard = (value: number): number => {
    const arcanosMayores: ArcanosMayores2Type[] = ArcanosMayores
    return arcanosMayores.findIndex((elem: ArcanosMayores2Type) => elem.codigo === value)
}

export interface ConsultsType {
    objectId: string
    clientName: string
    clientAnswer: string
    type: string
    cards: AllCardsType[]
    response: string
    user: string
    consult: string
    createdAt: Date
    updatedAt: Date
}

interface User {
    name: string
    date: string
    zodiac: string
    personality?: string
}

export interface DataType {
    consultante: User
    consultado: User
    consulta: string
    tipo?: string
}

export interface DataTypePregunta {
    consultante: User
    consulta: string
    tipo?: string
    preguntas?: boolean
}

export interface DataType2Preguntas {
    consultante: User
    consulta1: string
    consulta2: string
}

export interface DataType3Preguntas {
    consultante: User
    consulta1: string
    consulta2: string
    consulta3: string
}

export interface DataTypeVidaPasada {
    consultante: {
        name: string
        date: string
        zodiac: string
    }
    consulta: string
}
