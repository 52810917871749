import { DataType } from '../../../interfaces/utils'

export const interpret = (data: DataType, user: string, type: string) => {
    let consult = `Realiza un análisis de compatibilidad de pareja utilizando la astrología, las modalidades astrológicas y la numerología, brindando en el análisis un porcentaje de compatibilidad entre ellos, para ${data.consultante.name} con fecha de nacimiento ${data.consultante.date}, del signo de ${data.consultante.zodiac} y un número de personalidad ${data.consultante.personality} y ${data.consultado.name} con fecha de nacimiento ${data.consultado.date}, del signo de ${data.consultado.zodiac} y un número de personalidad ${data.consultado.personality}.  El análisis debe ser lo más detallada y extenso posible, pero a la vez utiliza un lenguaje sencillo de entender. Incluye en el análisis de compatibilidad sexual entre ellos y no menciones lo que no puedes hacer y realiza este análisis lo más realista posible, no trates de suavizar las cosas entre la pareja ni des consejos para mejorar.`

    let info = {
        consult: consult,
        user: user,
        clientName: data.consultante.name,
        clientAnswer: '',
        cards: [],
        type: type,
    }

    return info
}
