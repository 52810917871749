import React, { useEffect, useState } from 'react'
import logoExpress from '../../assets/images/navbar/tarot.png'
import logoEstelar from '../../assets/images/navbar/estelar.png'
import logoAstral from '../../assets/images/navbar/astral.png'
import logoCentral from '../../assets/images/navbar/sun.png'
import './navbar.css'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Dispatch } from 'redux'
import { Logout, SetUser } from '../../store/actions'
import { useWidth } from '../../hooks/useWidth'

const Navbar = () => {
    const navigate = useNavigate()
    const user = useSelector((state: any) => state.user)
    const dispatch: Dispatch<any> = useDispatch()
    const location = useLocation()
    const [item, setItem] = useState('')
    const [show, setShow] = useState(false)
    const width = useWidth()
    const dominio = window.location.host

    useEffect(() => {
        switch (location.pathname) {
            case '/how-it-works':
                setItem('como funciona')
                break
            case '/manual/three':
            case '/auto/pregunta':
            case '/auto/love':
            case '/general':
            case '/auto/2-preguntas':
            case '/auto/3-preguntas':
            case '/auto/compatibilidad':
            case '/auto/free':
            case '/auto/vidapasada':
                case '/auto/evolutivo':
                setItem('consultas')
                break
            case '/iching/pregunta':
                setItem('iching')
                break
            case '/login':
                setItem('login')
                break
            case '/about':
                setItem('about')
                break
            default: {
                setItem('home')
                break
            }
        }
        if (Object.entries(user).length === 0) {
            let saveUser = localStorage.getItem('user')
            if (saveUser) {
                saveUser = JSON.parse(saveUser)
                dispatch(SetUser(saveUser))
            }
        }
    }, [location.pathname, dispatch, user])

    useEffect(() => {
        setShow(false)
    }, [width])

    const handleItems = (value: string): void => {
        if (
            (item !== 'consultas' && item !== 'clientes' && item !== user.username) ||
            (item === 'consultas' && value === 'consultas')
        ) {
            setShow(false)
        }
        setItem(value)
    }

    const handleLogout = () => {
        dispatch(Logout())
        localStorage.removeItem('user')
        navigate('/')
    }

    return (
        <div className="w-full h-[60px] flex flex-row items-center justify-between z-[99] sticky top-0 bg-white mini:px-3 lg:px-20">
            <Link to="/" className="no-underline">
                {dominio === 'www.tarotestelar.com.ar' ? (
                    <div className="flex items-center gap-[10px]">
                        <img src={logoEstelar} alt="" className="w-[50px] h-[50px]" />
                        <span className="mini:text-base xl:text-3xl font-black">Tarot</span>
                        <span className="mini:text-base xl:text-3xl font-black text-violetDark">Estelar</span>
                    </div>
                ) : dominio === 'www.tarotastral.com.ar' ? (
                    <div className="flex items-center gap-[10px]">
                        <img src={logoAstral} alt="" className="w-[50px] h-[50px]" />
                        <span className="mini:text-base xl:text-3xl font-black">Tarot</span>
                        <span className="mini:text-base xl:text-3xl font-black text-violetDark">Astral</span>
                    </div>
                ) : dominio === 'www.tarotcentral.com.ar' ? (
                    <div className="flex items-center gap-[10px] rounded-full">
                        <img src={logoCentral} alt="" className="w-[50px] h-[50px] rounded-full" />
                        <span className="mini:text-base xl:text-3xl font-black">Tarot</span>
                        <span className="mini:text-base xl:text-3xl font-black text-violetDark">Central</span>
                    </div>
                ) : (
                    <div className="flex items-center gap-[10px]">
                        <img src={logoExpress} alt="" className="w-[50px] h-[50px]" />
                        <span className="mini:text-base xl:text-3xl font-black">Tarot</span>
                        <span className="mini:text-base xl:text-3xl font-black text-violetDark">Express</span>
                    </div>
                )}
            </Link>
            <div
                className={
                    show
                        ? 'mini:absolute xl:relative flex xl:flex-row mini:flex-col mini:items-start lx:items-center mini:gap-5 mini:top-[60px] xl:top-auto mini:right-0 xl:right-auto mini:bg-white mini:p-5 xl:p-0 mini:rounded-bl-3xl xl:rounded-none xl:gap-[calc(100vw * 0.001)] shadow-lg translate-x-0 duration-500'
                        : 'flex xl:flex-row mini:flex-col mini:absolute xl:relative mini:top-[60px] xl:top-auto mini:right-0 xl:right-auto mini:bg-white xl:bg-transparent mini:items-start mini:p-5 xl:p-0 mini:rounded-bl-xl xl:rounded-none xl:items-center xl:gap-[calc(100vw * 0.001)] mini:translate-x-full xl:translate-x-0 duration-500'
                }
            >
                <Link to="/how-it-works" className="no-underline">
                    <div
                        className={
                            item === 'como funciona'
                                ? 'text-base font-semibold py-[5px] px-[25px] text-white bg-violetDark rounded-2xl'
                                : 'text-base font-normal py-[5px] px-[25px] cursor-pointer hover:scale-110 duration-200'
                        }
                        onClick={() => handleItems('como funciona')}
                    >
                        Cómo Funciona
                    </div>
                </Link>

                <Link to="/about" className="no-underlinek">
                    <div
                        className={
                            item === 'about'
                                ? 'text-base font-semibold py-[5px] px-[25px] text-white bg-violetDark rounded-2xl'
                                : 'text-base font-normal py-[5px] px-[25px] cursor-pointer hover:scale-110 duration-200'
                        }
                        onClick={() => handleItems('about')}
                    >
                        Sobre el Tarot
                    </div>
                </Link>

                {Object.entries(user).length > 0 && (
                    <div className="relative align-middle h-full" id="consultas">
                        <div className="group/consultas">
                            <div
                                className={
                                    item === 'consultas'
                                        ? 'text-base font-semibold py-[5px] px-[25px] text-white bg-violetDark rounded-2xl'
                                        : 'text-base font-normal py-[5px] px-[25px] cursor-pointer hover:scale-110 duration-200'
                                }
                            >
                                Consultas
                            </div>

                            <div className="mini:relative xl:absolute bg-white xl:rounded-b-xl xl:top-[55px] xl:py-[10px] xl:px-[20px] hidden group-hover/consultas:block">
                                <Link to="/manual/three" className="no-underline">
                                    <div
                                        className="mini:text-sm xl:text-base font-normal w-[calc(100% - 44px)] py-[7px] px-[24px] whitespace-nowrap hover:bg-zinc-100"
                                        onClick={() => handleItems('consultas')}
                                    >
                                        Manual
                                    </div>
                                </Link>
                                <Link to="/auto/sino" className="no-underline">
                                    <div
                                        className="mini:text-sm xl:text-base font-normal w-[calc(100% - 44px)] py-[7px] px-[24px] whitespace-nowrap hover:bg-zinc-100"
                                        onClick={() => handleItems('consultas')}
                                    >
                                        Tirada del SI o NO
                                    </div>
                                </Link>
                                <Link to="/auto/pregunta" className="no-underline">
                                    <div
                                        className="mini:text-sm xl:text-base font-normal w-[calc(100% - 44px)] py-[7px] px-[24px] whitespace-nowrap hover:bg-zinc-100"
                                        onClick={() => handleItems('consultas')}
                                    >
                                        Tirada para Una Pregunta
                                    </div>
                                </Link>
                                <Link to="/auto/2-preguntas" className="no-underline">
                                    <div
                                        className="mini:text-sm xl:text-base font-normal w-[calc(100% - 44px)] py-[7px] px-[24px] whitespace-nowrap hover:bg-zinc-100"
                                        onClick={() => handleItems('consultas')}
                                    >
                                        Tirada para Dos Pregunta
                                    </div>
                                </Link>
                                <Link to="/auto/3-preguntas" className="no-underline">
                                    <div
                                        className="mini:text-sm xl:text-base font-normal w-[calc(100% - 44px)] py-[7px] px-[24px] whitespace-nowrap hover:bg-zinc-100"
                                        onClick={() => handleItems('consultas')}
                                    >
                                        Tirada para Tres Pregunta
                                    </div>
                                </Link>
                                <Link to="/auto/love" className="no-underline">
                                    <div
                                        className="mini:text-sm xl:text-base font-normal w-[calc(100% - 44px)] py-[7px] px-[24px] whitespace-nowrap hover:bg-zinc-100"
                                        onClick={() => handleItems('consultas')}
                                    >
                                        Tirada del Amor
                                    </div>
                                </Link>
                                <Link to="/general" className="no-underline">
                                    <div
                                        className="mini:text-sm xl:text-base font-normal w-[calc(100% - 44px)] py-[7px] px-[24px] whitespace-nowrap hover:bg-zinc-100"
                                        onClick={() => handleItems('consultas')}
                                    >
                                        Tirada General
                                    </div>
                                </Link>
                                <Link to="/auto/celta" className="no-underline">
                                    <div
                                        className="mini:text-sm xl:text-base font-normal w-[calc(100% - 44px)] py-[7px] px-[24px] whitespace-nowrap hover:bg-zinc-100"
                                        onClick={() => handleItems('consultas')}
                                    >
                                        Tirada de la Cruz Celta
                                    </div>
                                </Link>
                                <Link to="/auto/vidapasada" className="no-underline">
                                    <div
                                        className="mini:text-sm xl:text-base font-normal w-[calc(100% - 44px)] py-[7px] px-[24px] whitespace-nowrap hover:bg-zinc-100"
                                        onClick={() => handleItems('consultas')}
                                    >
                                        Vida Pasada
                                    </div>
                                </Link>
                                <Link to="/auto/evolutivo" className="no-underline">
                                    <div
                                        className="mini:text-sm xl:text-base font-normal w-[calc(100% - 44px)] py-[7px] px-[24px] whitespace-nowrap hover:bg-zinc-100"
                                        onClick={() => handleItems('consultas')}
                                    >
                                        Tarot Evolutivo
                                    </div>
                                </Link>
                                <Link to="/auto/compatibilidad" className="no-underline">
                                    <div
                                        className="mini:text-sm xl:text-base font-normal w-[calc(100% - 44px)] py-[7px] px-[24px] whitespace-nowrap hover:bg-zinc-100"
                                        onClick={() => handleItems('consultas')}
                                    >
                                        Compatibilidad de la Pareja
                                    </div>
                                </Link>
                                <Link to="/auto/free" className="no-underline">
                                    <div
                                        className="mini:text-sm xl:text-base font-normal w-[calc(100% - 44px)] py-[7px] px-[24px] whitespace-nowrap hover:bg-zinc-100"
                                        onClick={() => handleItems('consultas')}
                                    >
                                        Realizar tirada gratuita
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                )}
                {Object.entries(user).length > 0 && (
                    <div className="relative align-middle h-full" id="clientes">
                        <div className="group/clientes">
                            <Link to="/iching/pregunta" className="no-underline">
                                <div
                                    className={
                                        item === 'iching'
                                            ? 'text-base font-semibold py-[5px] px-[25px] text-white bg-violetDark rounded-2xl'
                                            : 'text-base font-normal py-[5px] px-[25px] cursor-pointer hover:scale-110 duration-200'
                                    }
                                    onClick={() => handleItems('iching')}
                                >
                                    I-Ching
                                </div>
                            </Link>
                        </div>
                    </div>
                )}

                <div
                    className={
                        item === 'contacto'
                            ? 'text-base font-semibold py-[5px] px-[25px] text-white bg-violetDark rounded-2xl'
                            : 'text-base font-normal py-[5px] px-[25px] cursor-pointer hover:scale-110 duration-200'
                    }
                    onClick={() => handleItems('contacto')}
                >
                    Contacto
                </div>

                {Object.entries(user).length === 0 && (
                    <Link to="/login" className="no-underline">
                        <div
                            className={
                                item === 'login'
                                    ? 'text-base font-semibold py-[5px] px-[25px] text-white bg-violetDark rounded-2xl'
                                    : 'text-base font-normal py-[5px] px-[25px] cursor-pointer hover:scale-110 duration-200'
                            }
                            onClick={() => handleItems('login')}
                        >
                            Login
                        </div>
                    </Link>
                )}
                {Object.entries(user).length > 0 && (
                    <div className="relative align-middle h-full" id="user">
                        <div className="group/user">
                            <div
                                className={
                                    item === user.username
                                        ? 'text-base font-semibold py-[5px] px-[25px] text-white bg-violetDark rounded-2xl'
                                        : 'text-base font-normal py-[5px] px-[25px] cursor-pointer hover:scale-110 duration-200'
                                }
                                onClick={() => handleItems(user.username)}
                            >
                                {user.username}
                            </div>

                            <div
                                className="absolute bg-white rounded-b-xl top-[55px] py-[10px] px-[20px] hidden group-hover/user:block"
                                id="submenuuser"
                            >
                                <Link to="/user/myconsults" className="no-underline">
                                    <div
                                        className="text-base font-normal w-[calc(100% - 44px)] py-[7px] px-[20px] whitespace-nowrap hover:bg-zinc-100"
                                        onClick={() => handleItems(user.username)}
                                    >
                                        Mis Consultas
                                    </div>
                                </Link>
                                {user.level === 1 && (
                                    <>
                                        <Link to="/user/allconsults" className="no-underline">
                                            <div
                                                className="text-base font-normal w-[calc(100% - 44px)] py-[7px] px-[20px] whitespace-nowrap hover:bg-zinc-100"
                                                onClick={() => handleItems(user.name)}
                                            >
                                                Todas las Consultas
                                            </div>
                                        </Link>
                                        <div className="text-base font-normal w-[calc(100% - 44px)] py-[7px] px-[20px] whitespace-nowrap hover:bg-zinc-100">
                                            Usuarios
                                        </div>
                                    </>
                                )}
                                <div
                                    className="text-base font-normal w-[calc(100% - 44px)] py-[7px] px-[20px] whitespace-nowrap hover:bg-zinc-100"
                                    onClick={() => handleLogout()}
                                >
                                    Cerrar sesión
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <div
                className={
                    show ? 'w-fit mini:block xl:hidden duration-500 pr-2' : 'w-fit mini:block xl:hidden duration-500'
                }
                onClick={() => setShow(!show)}
            >
                <div
                    className={
                        show
                            ? 'w-[35px] h-[1px] border-2 border-solid border-[#504e4e] bg-[#504e4e] rounded-sm duration-500 mb-2 rotate-45 translate-x-2 translate-y-[10px]'
                            : 'w-[35px] h-[1px] border-2 border-solid border-[#504e4e] bg-[#504e4e] rounded-sm duration-500 mb-2'
                    }
                ></div>
                <div
                    className={
                        show
                            ? 'w-[35px] h-[1px] border-2 border-solid border-[#504e4e] bg-[#504e4e] rounded-sm duration-500 mb-2 opacity-0'
                            : 'w-[35px] h-[1px] border-2 border-solid border-[#504e4e] bg-[#504e4e] rounded-sm duration-500 mb-2'
                    }
                ></div>
                <div
                    className={
                        show
                            ? 'w-[35px] h-[1px] border-2 border-solid border-[#504e4e] bg-[#504e4e] rounded-sm duration-500 -rotate-45 translate-x-2 -translate-y-[14px]'
                            : 'w-[35px] h-[1px] border-2 border-solid border-[#504e4e] bg-[#504e4e] rounded-sm duration-500'
                    }
                ></div>
            </div>
        </div>
    )
}

export default Navbar
