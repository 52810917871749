export const Spanish = {
  translation: {
    name: "Nombre",
    throw_of_yes_or_no: "Tirada de si o no",
    how_it_works: "Como Funciona",
    about_tarot: "Sobre el Tarot",
    consults: "Consultas",
    clients: "Clientes",
    contact: "Contacto",
    birthdate: "Fecha de Nacimiento",
    ask: "Pregunta",
    done: "Hecho",
    shuffle: "Barajar",
    interpret: "Interpretar",
    edit: "Editar",
    new_consult: "Nueva Consulta",
    new_client: "Nuevo Cliente",
    see_text: "Ver Texto",
    return: "Volver",
  },
};
