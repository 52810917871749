import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination, A11y, Autoplay } from 'swiper'
import Love from './love/love'
import Family from './family/family'
import Job from './job/job'
import Money from './money/money'
import Trips from './trips/trips'
import Moving from './moving/moving'

const Slider: React.FC = (): JSX.Element => {
    const screenWidth = window.innerWidth

    return (
        <Swiper
            modules={[Navigation, Pagination, Autoplay, A11y]}
            slidesPerView={1}
            navigation={screenWidth > 480}
            pagination={{ clickable: true }}
            autoplay={{ delay: 10000, pauseOnMouseEnter: true }}
            loop
            speed={500}
        >
            <SwiperSlide>{({ isActive }) => <Love isVisible={isActive} />}</SwiperSlide>
            <SwiperSlide>{({ isActive }) => <Family isVisible={isActive} />}</SwiperSlide>
            <SwiperSlide>{({ isActive }) => <Job isVisible={isActive} />}</SwiperSlide>
            <SwiperSlide>{({ isActive }) => <Money isVisible={isActive} />}</SwiperSlide>
            <SwiperSlide>{({ isActive }) => <Trips isVisible={isActive} />}</SwiperSlide>
            <SwiperSlide>{({ isActive }) => <Moving isVisible={isActive} />}</SwiperSlide>
        </Swiper>
    )
}

export default Slider
