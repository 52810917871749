export const GET_ALL_CARDS = "GET_ALL_CARDS";
export const SAVE_ALL_CARDS = "SAVE_ALL_CALLS";
export const LOGIN = "LOGIN";
export const ERROR = "ERROR";
export const ERROR_LOGIN = "ERROR_LOGIN";
export const RESET_ERROR = "RESET_ERROR";
export const SET_USER = "SET_USER";
export const GET_ALL_CONSULTS = "GET_ALL_CONSULTS";
export const LOGOUT = "LOGOUT";
export const SAVE_CONSULT = "SAVE_CONSULT";
export const RESET_CONSULT = "RESET_CONSULT";
