import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "../../../../languages/i18n";
import styles from "./yesorno.module.css";

const ManualYesOrNo: React.FC = (): JSX.Element => {
  const { t } = useTranslation();
  const [status, setStatus] = useState({
    isDone: false,
    isDistributed: false,
    isInterpreted: false,
  });
  const [data, setData] = useState({
    name: "",
    birthdate: "",
    ask: "",
  });

  const handleInput = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };

  const handleStatus = (name: string, value: boolean): void => {
    setStatus({
      ...status,
      [name]: value,
    });
  };

  return (
    <div className={styles.body}>
      <div className={styles.title_container}>
        <div className={styles.title}>{t("throw_of_yes_or_no")}</div>
      </div>
      <div className={styles.container}>
        <div className={styles.panel}>
          <div className={styles.inputs_container}>
            <div className={styles.input_container}>
              <div className={styles.label}>{t("name")}</div>
              <input
                type="text"
                className={styles.input}
                name="name"
                value={data.name}
                onChange={(e) => handleInput(e)}
              />
            </div>
            <div className={styles.input_container}>
              <div className={styles.label}>{t("birthdate")}</div>
              <input
                type="date"
                className={styles.input}
                name="birthdate"
                value={data.birthdate}
                onChange={(e) => handleInput(e)}
              />
            </div>
          </div>
          <br />
          <div className={styles.input_container}>
            <div className={styles.label}>{t("ask")}</div>
            <textarea
              name="ask"
              className={styles.textarea}
              value={data.ask}
              onChange={(e) => handleInput(e)}
            ></textarea>
          </div>
          <div className={styles.buttons_container}>
            <button
              className={styles.button}
              disabled={data.name === "" || data.ask === "" || status.isDone}
            >
              {t("done")}
            </button>
            <button
              className={styles.button}
              disabled={!status.isDone || status.isDistributed}
            >
              {t("shuffle")}
            </button>
            <button
              className={styles.button}
              disabled={!status.isDistributed || status.isInterpreted}
            >
              {t("interpret")}
            </button>
            <button
              className={styles.button}
              disabled={!status.isDone || status.isInterpreted}
            >
              {t("edit")}
            </button>
            <button className={styles.button} disabled={!status.isInterpreted}>
              {t("new_consult")}
            </button>
            <button className={styles.button} disabled={!status.isInterpreted}>
              {t("new_client")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManualYesOrNo;
