import React from 'react'
import HomeWorkIcon from '@mui/icons-material/HomeWork'
import styles from '../love/love.module.css'

interface MovingType {
    isVisible: boolean
}

const Moving: React.FC<MovingType> = (props: MovingType): JSX.Element => {
    return (
        <div className={styles.body}>
            <div className={styles.body_content}>
                <div className={styles.title_container}>
                    <div className={props.isVisible ? styles.circle + ' ' + styles.circle_show : styles.circle}>
                        <HomeWorkIcon sx={{ color: '#ffffff', fontSize: 40 }} />
                    </div>
                    <div className={styles.sobrecircle}></div>
                    <div className={styles.title}>
                        MUDANZAS
                        <div className={styles.line}></div>
                    </div>
                </div>
                <div className={styles.parrafo}>
                    <p>
                        Mudarse a un nuevo lugar puede ser una experiencia emocionante y desafiante al mismo tiempo.
                        Puede traer nuevos comienzos y oportunidades, pero también puede ser abrumador y estresante.
                    </p>
                    <p>
                        A través de las cartas del tarot, podemos explorar tus deseos y necesidades en relación a las
                        mudanzas. Podemos descubrir nuevas formas de abordar los desafíos de las mudanzas y encontrar
                        soluciones a tus preocupaciones. Ya sea que estés buscando un nuevo hogar, planeando una mudanza
                        importante o simplemente necesites un poco de orientación durante el proceso de mudanza.
                    </p>
                </div>
            </div>
            <img
                src={require('../../../assets/images/homepage/moving.jpg')}
                alt=""
                className={props.isVisible ? styles.img_love + ' ' + styles.show : styles.img_love}
            />
        </div>
    )
}

export default Moving
