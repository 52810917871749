import React, { Dispatch, useEffect, useState } from 'react'
import { DataType } from '../../../interfaces/utils'
import { copyResponse, fotoCartas, getZodiac, isEmpty, selectDiez, setMezcla } from '../../../utils/functions'
import { useWidth } from '../../../hooks/useWidth'
import { ButtonNormal } from '../../../components/buttons/button-normal'
import { useDispatch, useSelector } from 'react-redux'
import { GetConsult, SaveAllCards } from '../../../store/actions'
import { AllCardsType } from '../../../data/all-cards'
import { ShowCards } from './show-cards'
import { interpret } from './functions'
import { PhotoCards } from './photo-cards'
import { Compatibilidad, CompatibilidadType, Zodiaco, ZodiacoType } from '../../../data/zodiaco'

const Celta: React.FC = (): JSX.Element => {
    const width = useWidth()
    const dispatch: Dispatch<any> = useDispatch()
    const [data, setData] = useState<DataType>({
        consultante: {
            name: '',
            date: '',
            zodiac: '',
        },
        consultado: {
            name: '',
            date: '',
            zodiac: '',
        },
        consulta: '',
    })
    const [copia, setCopia]: any = useState(false)
    const { allCards, user, consult } = useSelector((state: any) => state)
    const [response, setResponse] = useState('')
    const [selectedCards, setSelectedCards] = useState<AllCardsType[]>([])
    const [showResponse, setShowResponse] = useState<boolean>(false)
    const [status, setStatus] = useState({
        isDone: false,
        mezcladas: false,
        repartidas: false,
        isConsulting: false,
        isInterpret: false,
    })
    const zodiaco: ZodiacoType[] = Zodiaco
    const compatibilidad: CompatibilidadType[] = Compatibilidad

    const nuevaPregunta = () => {
        setResponse('')
        setStatus({
            ...status,
            isDone: false,
            mezcladas: false,
            repartidas: false,
            isInterpret: false,
        })
        setData({
            ...data,
            consulta: '',
        })
        setShowResponse(false)
        setSelectedCards([])
    }

    const nuevoCliente = () => {
        setResponse('')
        setStatus({
            ...status,
            isDone: false,
            mezcladas: false,
            repartidas: false,
            isInterpret: false,
        })
        setData({
            consultante: {
                name: '',
                date: '',
                zodiac: '',
            },
            consultado: {
                name: '',
                date: '',
                zodiac: '',
            },
            consulta: '',
        })
        setShowResponse(false)
        setSelectedCards([])
    }

    // MANEJA LOS ESTADOS
    const handleStatus = (name: string, value: boolean): void => {
        setStatus({
            ...status,
            [name]: value,
        })
    }

    const handleZodiacConsultant = (date: string): void => {
        let zodiac = getZodiac(date)
        setData({
            ...data,
            consultante: {
                ...data.consultante,
                zodiac,
                date,
            },
        })
    }

    const handleZodiacThird = (date: string): void => {
        let zodiac = getZodiac(date)
        setData({
            ...data,
            consultado: {
                ...data.consultado,
                zodiac,
                date,
            },
        })
    }

    const handleInputConsultant = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (event.target.name === 'date' && parseInt(event.target.value.slice(0, 4)) > 1900) {
            handleZodiacConsultant(event.target.value)
        } else {
            setData({
                ...data,
                consultante: {
                    ...data.consultante,
                    [event.target.name]: event.target.value,
                },
            })
        }
    }

    const handleInputThird = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (event.target.name === 'date' && parseInt(event.target.value.slice(0, 4)) > 1900) {
            handleZodiacThird(event.target.value)
        } else {
            setData({
                ...data,
                consultado: {
                    ...data.consultado,
                    [event.target.name]: event.target.value,
                },
            })
        }
    }

    const handleConsult = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
        setData({
            ...data,
            consulta: event.target.value,
        })
    }

    const mezcla = () => {
        const mezcladas = setMezcla(allCards)
        //console.log(mezcladas)
        dispatch(SaveAllCards(mezcladas))
        handleStatus('mezcladas', true)
    }

    //const fotoCards = () => {}

    const interpretar = () => {
        handleStatus('isConsulting', true)
        const info = interpret(selectedCards, data, user.username, 'celta')
        dispatch(GetConsult(info))
        //handleStatus('isConsulting', false)
        //setShowResponse(true)
    }

    useEffect(() => {
        if (status.mezcladas && !status.repartidas) {
            setSelectedCards(selectDiez(allCards))
            handleStatus('repartidas', true)
        }
    }, [allCards, status])

    useEffect(() => {
        if (!isEmpty(consult)) {
            setStatus({
                ...status,
                isConsulting: false,
                isInterpret: true,
            })
            setResponse(consult.response)
            setShowResponse(true)
            //console.log(consult)
        }
    }, [consult])

    useEffect(() => {
        setStatus({
            isConsulting: false,
            isInterpret: false,
            isDone: false,
            repartidas: false,
            mezcladas: false,
        })
        setShowResponse(false)
        setResponse('')
        setSelectedCards([])
    }, [])

    useEffect(() => {
        if (showResponse) {
            let signoConsultante = document.getElementById('consultante')
            if (signoConsultante && data.consultante.zodiac.length > 0) {
                let description = zodiaco.filter((signo) => signo.name === data.consultante.zodiac)
                signoConsultante.innerHTML = description[0].description
            }
            if (data.consultado.zodiac.length > 0) {
                let signoTercero = document.getElementById('tercero')
                if (signoTercero) {
                    let description = zodiaco.filter((signo) => signo.name === data.consultado.zodiac)
                    signoTercero.innerHTML = description[0].description
                }
                let compatib = document.getElementById('comp')
                if (compatib) {
                    let texto = compatibilidad.filter(
                        (elem) =>
                            elem.signos === data.consultante.zodiac + '-' + data.consultado.zodiac ||
                            elem.signos === data.consultado.zodiac + '-' + data.consultante.zodiac
                    )
                    compatib.innerHTML = texto[0].description
                }
            }
        }
    }, [showResponse, data, zodiaco, compatibilidad])

    // COPIA LA IMAGEN DE LAS CARTAS
    useEffect(() => {
        if (copia) {
            let element = document.getElementById('photo')
            if (element) {
                fotoCartas(element, data.consultante.name)
                setCopia(false)
            }
        }
    }, [copia])

    return (
        <div className="w-full mini:min-h-screen mini:h-full xl:h-height-main bg-celta bg-no-repeat bg-cover px-4 relative mini:pb-10 xl:pb-0">
            <p className="mini:text-xl xl:text-4xl font-bold text-white italic w-full text-center py-5">
                Tirada de la Cruz Celta
            </p>
            <div className="flex mini:flex-col xl:flex-row w-full">
                {/* left */}
                <div className="mini:w-full xl:w-1/2 bg-violetLight h-full rounded-lg mini:px-2 mini:py-5 md:p-5 flex flex-col gap-6 shadow-card-horizontal">
                    <div className="flex mini:flex-col md:flex-row gap-3">
                        <input
                            name="name"
                            type="text"
                            value={data.consultante.name}
                            onChange={(e) => handleInputConsultant(e)}
                            placeholder="Nombre del Consultante"
                            className="h-10 mini:w-full md:w-1/2 pl-3 rounded-lg text-base text-gray-400 focus-visible:outline-none focus-visible:border-violetDark focus-visible:border-2"
                            autoFocus={true}
                        />
                        <div className="flex gap-3">
                            <input
                                type="date"
                                name="date"
                                value={data.consultante.date}
                                onChange={(e) => handleInputConsultant(e)}
                                placeholder="Nacimiento"
                                className="pl-3 pr-1lh-10 w-1/2 rounded-lg text-base text-gray-400 focus-visible:outline-none focus-visible:border-violetDark focus-visible:border-2"
                            />
                            <input
                                type="text"
                                name="zodiac"
                                value={data.consultante.zodiac}
                                readOnly={true}
                                className="px-3 h-10 rounded-lg text-base text-gray-400 w-1/2 disabled:bg-white"
                                disabled={true}
                            />
                        </div>
                    </div>
                    <div className="flex mini:flex-col md:flex-row gap-3">
                        <input
                            name="name"
                            type="text"
                            value={data.consultado.name}
                            onChange={(e) => handleInputThird(e)}
                            placeholder="Nombre del Consultado"
                            className="h-10 mini:w-full md:w-1/2 pl-3 rounded-lg textr-base text-gray-400 focus-visible:outline-none focus-visible:border-violetDark focus-visible:border-2"
                        />
                        <div className="flex gap-3">
                            <input
                                type="date"
                                name="date"
                                value={data.consultado.date}
                                onChange={(e) => handleInputThird(e)}
                                placeholder="Nacimiento"
                                className="pl-3 pr-1 h-10 w-1/2 rounded-lg textr-base text-gray-400 focus-visible:outline-none focus-visible:border-violetDark focus-visible:border-2"
                            />
                            <input
                                type="text"
                                name="zodiac"
                                value={data.consultado.zodiac}
                                readOnly={true}
                                className="px-3 h-10 rounded-lg textr-base text-gray-400 w-1/2 disabled:bg-white"
                                disabled={true}
                            />
                        </div>
                    </div>
                    <div>
                        <textarea
                            name="consult"
                            value={data.consulta}
                            className="p-3 h-[40vh] rounded-lg textr-base text-gray-400 w-full"
                            placeholder="Consulta"
                            onChange={(e) => handleConsult(e)}
                        />
                    </div>
                    <div className="flex gap-3 justify-center flex-wrap disabled:hidden">
                        <ButtonNormal
                            caption="Hecho"
                            onClick={() => handleStatus('isDone', true)}
                            disabled={
                                status.isDone ||
                                data.consultante.name.length === 0 ||
                                data.consultante.date.length === 0 ||
                                data.consulta.length === 0
                            }
                        />
                        <ButtonNormal
                            caption="Repartir"
                            onClick={() => mezcla()}
                            disabled={!status.isDone || status.repartidas}
                        />
                        <ButtonNormal
                            caption="Editar"
                            onClick={() => handleStatus('isDone', false)}
                            disabled={!status.isDone || status.repartidas}
                        />
                        <ButtonNormal
                            caption="Hacer foto de cartas"
                            onClick={() => setCopia(true)}
                            disabled={!status.repartidas}
                        />
                        <ButtonNormal
                            caption={status.isConsulting ? 'Interpretando' : 'Interpretar'}
                            onClick={() => interpretar()}
                            disabled={!status.repartidas || status.isInterpret}
                            loading={status.isConsulting}
                        />
                        <ButtonNormal
                            caption={status.isConsulting ? 'Reinterpretando' : 'Reinterpretar'}
                            onClick={() => interpretar()}
                            disabled={!status.isInterpret}
                            loading={status.isConsulting}
                        />
                        <ButtonNormal
                            caption="Ver Respuesta"
                            onClick={() => setShowResponse(true)}
                            disabled={response.length === 0}
                        />
                    </div>
                </div>
                {selectedCards.length > 0 ? (
                    <div className="mini:w-full xl:w-1/2 relative">
                        <ShowCards selectedCards={selectedCards} />
                    </div>
                ) : null}
            </div>
            {selectedCards.length > 0 ? (
                <div
                    className={`absolute mini:w-[96vw] xl:w-[60vw] mini:h-[50vh] xl:h-[84vh] mini:top-[25%] xl:top-[2%] mini:left-[2%] xl:left-[15%] ${
                        copia ? 'block' : 'hidden'
                    }`}
                    id="photo"
                >
                    <PhotoCards cards={selectedCards} />
                </div>
            ) : null}
            {showResponse ? (
                <div className="absolute w-[90vw] h-[90vh] top-[2%] left-[5%] bg-zinc-200 shadow-2xl flex flex-row gap-5 rounded-xl mini:px-2 mini:py-5 md:p-5">
                    <div className="mini:w-full xl:w-2/3 flex flex-col gap-3">
                        <textarea
                            className="w-full h-full resize-none rounded-lg mini:p-2 md:p-5"
                            value={response}
                            onChange={(e) => setResponse(e.target.value)}
                        />
                        <div className="flex flex-row flex-wrap justify-center gap-1">
                            <ButtonNormal caption="Volver" onClick={() => setShowResponse(false)} />
                            <ButtonNormal caption="Copiar Respuesta" onClick={() => copyResponse(response)} />
                            <ButtonNormal caption="Nueva Pregunta" onClick={() => nuevaPregunta()} />
                            <ButtonNormal caption="Nuevo Cliente" onClick={() => nuevoCliente()} />
                        </div>
                    </div>
                    <div className="w-1/3 flex flex-col gap-3 mini:hidden xl:block">
                        <p className="font-semibold">Consultante: {data.consultante.zodiac}</p>
                        <div
                            className="w-full bg-zinc-50 p-5 rounded-lg text-sm overflow-y-auto"
                            id="consultante"
                        ></div>
                        {data.consultado.zodiac.length > 0 && (
                            <>
                                <p className="font-semibold">Consultado: {data.consultado.zodiac}</p>
                                <div
                                    className="w-full bg-zinc-50 p-5 rounded-lg text-sm overflow-y-auto"
                                    id="tercero"
                                ></div>
                            </>
                        )}
                        {data.consultado.zodiac.length > 0 && (
                            <>
                                <p className="font-semibold">
                                    Compatibilidades {data.consultante.zodiac + '-' + data.consultado.zodiac}
                                </p>
                                <div
                                    className="w.full bg-zinc-50 p-5 rounded-lg text-sm overflow-y-auto"
                                    id="comp"
                                ></div>
                            </>
                        )}
                    </div>
                </div>
            ) : null}
        </div>
    )
}

export default Celta
