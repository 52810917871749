import i18n from "i18next";
import { initReactI18next } from "react-i18next";
//import LanguageDetector from "i18next-browser-languagedetector"

import { English } from "./english";
import { Italian } from "./italian";
import { Spanish } from "./spanish";
import { Portuguese } from "./portuguese";

const resources = {
  es: Spanish,
  en: English,
  it: Italian,
  pt: Portuguese,
};

const userLang = navigator.language;
const defaultLocale = userLang.substring(0, 2);

i18n.use(initReactI18next).init({
  resources,
  lng: defaultLocale,
  fallbackLng: "es",

  keySeparator: false,

  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
