import React from 'react'
import { AllCardsType } from '../../../data/all-cards'

export const ShowCards = ({ selectedCards }: { selectedCards: AllCardsType[] }) => {
    return (
        <div className="w-full flex justify-center items-center mini:px-2 mini:py-10 xl:px-5 xl:py-0">
            <div className="w-full grid grid-cols-5 mini:gap-1 md:gap-3">
                {selectedCards.map((card: AllCardsType, index: number) => (
                    <div className="flex flex-col gap-2">
                        <img
                            src={require('../../../assets/cards/' + card.url + '/' + card.image)}
                            className="w-auto h-auto rounded-md"
                            alt={card.name}
                            key={index}
                        />
                        <div className="mini:auto bg-violetLight text-sm text-center mini:rounded-sm xl:rounded-md overflow-hidden">
                            {card.name}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}
