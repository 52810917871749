import React from 'react'
import { AllCardsType } from '../../../data/all-cards'
import { randomNumberInRange } from '../../../utils/functions'
import { useWidth } from '../../../hooks/useWidth'

const PhotoCards = ({ cards }: { cards: AllCardsType[] }) => {
    const width = useWidth()
    const translate = width >= 1280 ? 20 : 5

    return (
        <div className="mini:py-10 xl:py-20 mini:px-5 xl:px-[40px] flex flex-wrap justify-center items-center gap-5 w-full after:bg-white-gradient after:absolute after:w-full after:h-full after:z-20 after:top-0 after-left-0">
            {cards.map((card: AllCardsType, index: number) => {
                return (
                    <img
                        key={index}
                        src={require('../../../assets/cards/' + card.url + '/' + card.image)}
                        className={
                            card.url === 'mayores'
                                ? 'w-[9.76vw] h-auto mini:rounded-sm xl:rounded-[7px]'
                                : 'bg-white w-[9.76vw] h-auto mini:rounded-sm xl:rounded-[7px]'
                        }
                        style={{
                            transform:
                                'rotate(' +
                                `${card.position !== "invertida" ? randomNumberInRange(-5, 5) : randomNumberInRange(175, 185)}` +
                                'deg) translateY(' +
                                randomNumberInRange(-translate, translate) +
                                'px',
                        }}
                        alt=""
                    />
                )
            })}
        </div>
    )
}

export default PhotoCards
