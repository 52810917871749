import React from "react";
import styles from "./sidebar-answers.module.css";

interface SidebarAnswersType {
  setAnswer: (answer: string) => void;
  setConsult: (value: boolean) => void;
  reset: () => void;
  answer: string;
  selects: number;
  consult: boolean;
}

const SidebarAnswers = (props: SidebarAnswersType) => {
  const handleMenu = (value: string): void => {
    props.setAnswer(value);
  };

  return (
    <div className={styles.body}>
      <div className={styles.menu_container}>
        <div
          className={
            props.answer === "amor"
              ? styles.item + " " + styles.active
              : styles.item
          }
          onClick={() => handleMenu("amor")}
        >
          Amor
        </div>
        <div
          className={
            props.answer === "salud"
              ? styles.item + " " + styles.active
              : styles.item
          }
          onClick={() => handleMenu("salud")}
        >
          Salud
        </div>
        <div
          className={
            props.answer === "trabajo"
              ? styles.item + " " + styles.active
              : styles.item
          }
          onClick={() => handleMenu("trabajo")}
        >
          Trabajo
        </div>
        <div
          className={
            props.answer === "dinero"
              ? styles.item + " " + styles.active
              : styles.item
          }
          onClick={() => handleMenu("dinero")}
        >
          Dinero
        </div>
      </div>
      <div className={styles.button_container}>
        {!props.consult ? (
          <button
            className={styles.button_consult}
            disabled={props.selects < 3 || props.answer === ""}
            onClick={() => props.setConsult(true)}
          >
            Consultar
          </button>
        ) : (
          <button
            className={styles.button_consult}
            onClick={() => props.reset()}
          >
            Reset
          </button>
        )}
      </div>
    </div>
  );
};

export default SidebarAnswers;
