import React, { useEffect, useState } from "react";
import styles from "./all-consults.module.css";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { Link, useNavigate } from "react-router-dom";
import { isEmpty, resumen } from "../../../utils/functions";
import { GetAllConsults } from "../../../store/actions";

const AllConsults = (): JSX.Element => {
  const { consults, user, allCards } = useSelector((state: any) => state);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch: Dispatch<any> = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isEmpty(user)) {
      setIsLoading(true);
      dispatch(GetAllConsults());
    } else {
      navigate("/");
    }
  }, [dispatch, navigate, user]);

  useEffect(() => {
    setIsLoading(false);
  }, [consults]);

  return !isLoading && !isEmpty(consults) ? (
    <div className={styles.body}>
      <div className={styles.title}>Todas las Consultas</div>

      <table>
        <thead>
          <tr>
            <th>Nro</th>
            <th>Usuario</th>
            <th>Fecha</th>
            <th>Tipo</th>
            <th>Cliente</th>
            <th>Consulta</th>
            <th>Cartas</th>
            <th>Respuesta</th>
            <th>Consultar</th>
          </tr>
        </thead>
        <tbody>
          {consults?.map((consult: any, index: number) => {
            return (
              <tr
                className={index % 2 === 0 ? styles.gris : styles.white}
                key={index}
              >
                <td style={{ textAlign: "center" }}>{index + 1}</td>
                <td>{consult.user}</td>
                <td>
                  <div>{new Date(consult.createdAt).toLocaleDateString()}</div>
                  <div>{new Date(consult.createdAt).toLocaleTimeString()}</div>
                </td>
                <td>{consult.type}</td>
                <td>{consult.clientName}</td>
                <td>{resumen(consult.clientAnswer)}</td>
                <td>
                  {consult.cards.map((card: any, index: number) => {
                    return (
                      index < 2 && (
                        <div className={styles.nowrap}>{card.name}</div>
                      )
                    );
                  })}
                </td>
                <td>
                  <p>{resumen(consult.response)}</p>
                </td>
                <td className={styles.icon_container}>
                  <Link to={`/user/consults-view/${consult.objectId}`}>
                    <img
                      src={require("../../../assets/images/view.png")}
                      alt=""
                      className={styles.icon}
                    />
                  </Link>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  ) : isLoading && isEmpty(consults) ? (
    <div className={styles.spinne_container}>
      <div className={styles.spinner}></div>
      <span className={styles.text_spinner}>Recuperando datos...</span>
    </div>
  ) : (
    <div className={styles.nofound}>No existen consultas guardadas</div>
  );
};

export default AllConsults;
