import React, { useEffect, useState } from 'react'
import styles from './login.module.css'
import { useDispatch, useSelector } from 'react-redux'
import { Dispatch } from 'redux'
import noshowIcon from '../../assets/images/login/noshow.svg'
import showIcon from '../../assets/images/login/show.svg'
import { ResetError, UserLogin } from '../../store/actions'
import { redirect, useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'

const Login = () => {
    const navigate = useNavigate()
    const user = useSelector((state: any) => state.user)
    const errorLogin = useSelector((state: any) => state.errorLogin)
    const dispatch: Dispatch<any> = useDispatch()
    const [show, setShow] = useState(false)
    const [usuario, setUsuario] = useState({
        username: '',
        password: '',
    })

    useEffect(() => {
        if (errorLogin === 0 && Object.entries(user).length > 0) {
            localStorage.setItem('user', JSON.stringify(user))
            navigate('/')
        } else if (errorLogin > 2) {
            Swal.fire('Error', 'Máximos intentos permitidos', 'error')
            dispatch(ResetError())
            navigate('/')
        }
    }, [errorLogin, user, dispatch, navigate])

    const handleInput = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setUsuario({
            ...usuario,
            [event.target.name]: event.target.value,
        })
    }

    const handleLogin = async (): Promise<void> => {
        dispatch(UserLogin(usuario))
    }

    return (
        <div className="w-screen h-height-main bg-home bg-no-repeat bg-cover flex items-center justify-center">
            <div className="mini:w-[95vw] xl:w-[30vw] bg-violetLight rounded-2xl p-5 shadow-xl">
                <div className="text-2xl font-bold text-violetDark text-center">Login</div>
                <div className="py-[30px]">
                    <div className={styles.input_container}>
                        <div className={styles.label}>Nombre de Usuario</div>
                        <input type="text" name="username" className={styles.input} onChange={(e) => handleInput(e)} />
                    </div>
                    <div className={styles.input_container} style={{ position: 'relative' }}>
                        <div className={styles.label}>Contraseña</div>
                        <input
                            type={show ? 'text' : 'password'}
                            className={styles.input}
                            name="password"
                            onChange={(e) => handleInput(e)}
                            style={{ paddingRight: '40px', width: 'calc(100% - 40px)' }}
                        />
                        <img
                            src={!show ? showIcon : noshowIcon}
                            alt=""
                            className={styles.passIcons}
                            onClick={() => setShow(!show)}
                        />
                    </div>
                </div>
                <div className={styles.buttons_container}>
                    <div className={styles.button_cancel}>Cancelar</div>
                    <div className={styles.button_enter} onClick={() => handleLogin()}>
                        Entrar
                    </div>
                </div>
                {errorLogin > 0 && <div className={styles.error}>Nombre o contraseña incorrectos</div>}
            </div>
        </div>
    )
}

export default Login
