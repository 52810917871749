import React from "react";
import styles from "./como-funciona.module.css";

const ComoFunciona: React.FC = (): JSX.Element => {
  return (
    <div className={styles.body}>
      <h2>ComoFunciona</h2>
    </div>
  );
};

export default ComoFunciona;
