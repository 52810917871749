import React from "react";
import styles from "./card.module.css";
import { AllCardsType } from "../../data/all-cards";

interface CardType {
  card: AllCardsType;
}

const Card: React.FC<CardType> = (props: CardType): JSX.Element => {
  return (
    <img
      src={require("../../assets/cards/" +
        props.card.url +
        "/" +
        props.card.image)}
      alt=""
      className={
        props.card.url === "mayores" // sin es mayor
          ? props.card.position === "invertida" // si es mayor, no esta seleccionada pero si invertida
            ? styles.card + " " + styles.invertida
            : styles.card // si es mayor, no esta seleccionada y no esta invertida
          : props.card.position === "invertida" // si es menor, no está seleccionada y esta invertida
          ? styles.card + " " + styles.menores + " " + styles.invertida
          : styles.card + " " + styles.menores
      }
    />
  );
};

export default Card;
