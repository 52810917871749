import React from 'react'
import FlightIcon from '@mui/icons-material/Flight'
import styles from '../love/love.module.css'

interface TripsType {
    isVisible: boolean
}

const Trips: React.FC<TripsType> = (props: TripsType): JSX.Element => {
    return (
        <div className={styles.body}>
            <div className={styles.body_content}>
                <div className={styles.title_container}>
                    <div className={props.isVisible ? styles.circle + ' ' + styles.circle_show : styles.circle}>
                        <FlightIcon sx={{ color: '#ffffff', fontSize: 40 }} />
                    </div>
                    <div className={styles.sobrecircle}></div>
                    <div className={styles.title}>
                        VIAJES
                        <div className={styles.line}></div>
                    </div>
                </div>
                <div className={styles.parrafo}>
                    <p>
                        Los viajes son una de las formas más emocionantes de explorar el mundo y descubrir nuevas
                        culturas. Pero también pueden ser abrumadores y desafiantes.
                    </p>
                    <p>
                        A través de las cartas del tarot, podemos obtener una comprensión más profunda de tus deseos y
                        necesidades en relación a los viajes. Podemos explorar las posibilidades y descubrir nuevas
                        formas de abordar las situaciones de viaje. Ya sea que estés buscando una nueva aventura o
                        planeando un viaje importante.
                    </p>
                </div>
            </div>
            <img
                src={require('../../../assets/images/homepage/viajes.jpg')}
                alt=""
                className={props.isVisible ? styles.img_love + ' ' + styles.show : styles.img_love}
            />
        </div>
    )
}

export default Trips
