import React, { useEffect, useState } from 'react'
import { GetComplemento, GetHexagrama, GetLinea, copyResponse, getZodiac, isEmpty } from '../../../utils/functions'
import { ButtonNormal } from '../../../components/buttons/button-normal'
import { Zodiaco, ZodiacoType } from '../../../data/zodiaco'
import { Dispatch } from 'redux'
import { useDispatch, useSelector } from 'react-redux'
import { DataTypePregunta } from '../../../interfaces/utils'
import { GetConsult } from '../../../store/actions'
import { HexagramaType } from '../../../data/hexagramas'
import { interpret } from './functions'

const IchingPregunta = () => {
    const dispatch: Dispatch<any> = useDispatch()
    const [data, setData] = useState<DataTypePregunta>({
        consultante: {
            name: '',
            date: '',
            zodiac: '',
        },
        consulta: '',
    })
    const [showResponse, setShowResponse] = useState<boolean>(false)
    const [response, setResponse] = useState('')
    const [status, setStatus] = useState({
        isDone: false,
        mezcladas: false,
        repartidas: false,
        isConsulting: false,
        isInterpret: false,
    })
    const zodiaco: ZodiacoType[] = Zodiaco
    const [hexagrama, setHexagrama] = useState<any>([])
    const [complemento, setComplemento] = useState<HexagramaType | null>(null)
    const { user, consult } = useSelector((state: any) => state)

    // RECIBE UNA FECHA Y DEVUELVE EL SIGNO ZODIACAL
    const handleZodiacConsultant = (date: string): void => {
        let zodiac = getZodiac(date)
        setData({
            ...data,
            consultante: {
                ...data.consultante,
                zodiac,
                date,
            },
        })
    }

    // MANEJA LOS INPUTS DE LOS DATOS DEL CONSULTANTE
    const handleInputConsultant = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (event.target.name === 'date' && parseInt(event.target.value.slice(0, 4)) > 1900) {
            handleZodiacConsultant(event.target.value)
        } else {
            setData({
                ...data,
                consultante: {
                    ...data.consultante,
                    [event.target.name]: event.target.value,
                },
            })
        }
    }

    // MANEJA EL TEXTAREA DE LA CONSULTA
    const handleConsult = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
        setData({
            ...data,
            consulta: event.target.value,
        })
    }

    // MANEJA LOS ESTADOS
    const handleStatus = (name: string, value: boolean): void => {
        setStatus({
            ...status,
            [name]: value,
        })
    }

    // PREPARA LA INFORMACION Y DISPARA LA CONSULTA A LA IA
    const interpretar = () => {
        handleStatus('isConsulting', true)
        const info = interpret(hexagrama, complemento, data, user.username, 'ichin-pregunta')
        dispatch(GetConsult(info))
    }

    // TIRA MONEDAS Y GENERA HEXAGRAMA
    const tirar = () => {
        setHexagrama(GetHexagrama())
    }

    useEffect(() => {
        if (hexagrama.length > 0) {
            setComplemento(GetComplemento(hexagrama))
            handleStatus('repartidas', true)
        }
    }, [hexagrama])

    useEffect(() => {
        console.log('Complmento:', complemento)
    }, [complemento])

    // SETEA LOS ESTADOS PARA REALIZAR UNA NUEVA CONSULTA CON EL MISMO CLIENTE
    const nuevaPregunta = () => {
        setResponse('')
        setStatus({
            ...status,
            isDone: false,
            mezcladas: false,
            repartidas: false,
            isInterpret: false,
        })
        setData({
            ...data,
            consulta: '',
        })
        setShowResponse(false)
        setHexagrama([])
        setComplemento(null)
    }

    // LIMPIA LOS ESTADOS PARA ATENDER UN NUEVO CLIENTE
    const nuevoCliente = () => {
        setResponse('')
        setStatus({
            ...status,
            isDone: false,
            mezcladas: false,
            repartidas: false,
            isInterpret: false,
        })
        setData({
            consultante: {
                name: '',
                date: '',
                zodiac: '',
            },
            consulta: '',
        })
        setShowResponse(false)
        setHexagrama([])
        setComplemento(null)
    }

    useEffect(() => {
        if (!isEmpty(consult)) {
            setStatus({
                ...status,
                isConsulting: false,
                isInterpret: true,
            })
            setResponse(consult.response)
            setShowResponse(true)
        }
    }, [consult])

    // MUESTRA UNA DERSCRIPCION DEL SIGNO DEL ZODIACO DEL CONSULTANTE
    useEffect(() => {
        if (showResponse) {
            let signoConsultante = document.getElementById('consultante')
            if (signoConsultante && data.consultante.zodiac.length > 0) {
                let description = zodiaco.filter((signo) => signo.name === data.consultante.zodiac)
                signoConsultante.innerHTML = description[0].description
            }
        }
    }, [showResponse, data, zodiaco])

    useEffect(() => {
        setStatus({
            isConsulting: false,
            isInterpret: false,
            isDone: false,
            repartidas: false,
            mezcladas: false,
        })
        setShowResponse(false)
        setResponse('')
        setHexagrama([])
        setComplemento(null)
    }, [])

    return (
        <div className="w-full min-h-screen h-full bg-iching bg-no-repeat bg-cover px-4 relative mini:pb-10 xl:pb-0">
            <p className="mini:text-xl xl:text-4xl font-pacifico font-bold text-white italic w-full text-center py-5 drop-shadow-lg">
                Consulta al milenario I-Ching
            </p>
            <div className="flex mini:flex-col xl:flex-row w-full">
                {/* left */}
                <div className="mini:w-full xl:w-1/2 bg-violetLight h-full rounded-lg mini:px-2 mini:py-5 md:p-5 flex flex-col gap-6 shadow-card-horizontal">
                    <div className="flex mini:flex-col md:flex-row gap-3">
                        <input
                            name="name"
                            type="text"
                            value={data.consultante.name}
                            onChange={(e) => handleInputConsultant(e)}
                            placeholder="Nombre del Consultante"
                            className="h-10 mini:w-full md:w-1/2 pl-3 rounded-lg text-base text-gray-400 focus-visible:outline-none focus-visible:border-violetDark focus-visible:border-2"
                            autoFocus={true}
                        />
                        <div className="flex gap-3 w-1/2 mini:w-full md:w-auto">
                            <input
                                type="date"
                                name="date"
                                value={data.consultante.date}
                                onChange={(e) => handleInputConsultant(e)}
                                placeholder="Nacimiento"
                                className="pl-3 pr-1lh-10 mini:w-1/2 md:w-1/2 rounded-lg text-base text-gray-400 focus-visible:outline-none focus-visible:border-violetDark focus-visible:border-2"
                            />
                            <input
                                type="text"
                                name="zodiac"
                                value={data.consultante.zodiac}
                                readOnly={true}
                                className="px-3 h-10 rounded-lg text-base text-gray-400 mini:w-1/2 md:w-1/2 disabled:bg-white"
                                disabled={true}
                            />
                        </div>
                    </div>
                    <div>
                        <textarea
                            name="consult"
                            value={data.consulta}
                            className="p-3 h-[40vh] rounded-lg textr-base text-gray-400 w-full resize-none"
                            placeholder="Consulta"
                            onChange={(e) => handleConsult(e)}
                        />
                    </div>
                    <div className="flex gap-3 justify-center flex-wrap disabled:hidden">
                        <ButtonNormal
                            caption="Hecho"
                            onClick={() => handleStatus('isDone', true)}
                            disabled={
                                status.isDone ||
                                data.consultante.name.length === 0 ||
                                data.consultante.date.length === 0 ||
                                data.consulta.length === 0
                            }
                        />
                        <ButtonNormal
                            caption="Tirar monedas"
                            onClick={() => tirar()}
                            disabled={!status.isDone || status.repartidas}
                        />
                        <ButtonNormal
                            caption="Editar"
                            onClick={() => handleStatus('isDone', false)}
                            disabled={!status.isDone || status.repartidas}
                        />
                        {/* <ButtonNormal
                            caption="Hacer foto de cartas"
                            onClick={() => setCopia(true)}
                            disabled={!status.repartidas || status.isConsulting}
                        /> */}
                        <ButtonNormal
                            caption={status.isConsulting ? 'Interpretando' : 'Interpretar'}
                            onClick={() => interpretar()}
                            disabled={!status.repartidas || status.isInterpret}
                            loading={status.isConsulting}
                        />
                        <ButtonNormal
                            caption={status.isConsulting ? 'Reinterpretando' : 'Reinterpretar'}
                            onClick={() => interpretar()}
                            disabled={!status.isInterpret}
                            loading={status.isConsulting}
                        />
                        <ButtonNormal
                            caption="Ver Respuesta"
                            onClick={() => setShowResponse(true)}
                            disabled={response.length === 0}
                        />
                        <ButtonNormal
                            caption="Nueva Tirada"
                            onClick={() => nuevaPregunta()}
                            disabled={!status.isInterpret}
                        />
                        <ButtonNormal
                            caption="Nuevo Cliente"
                            onClick={() => nuevoCliente()}
                            disabled={!status.isInterpret}
                        />
                    </div>
                </div>
                {/* right */}
                {hexagrama.length > 0 && (
                    <div className="flex mini:flex-col md:flex-row justify-center mini:items-center gap-8 mini:w-full xl:w-1/2 mini:py-10 xl:py-0">
                        <div className="flex flex-col items-center justify-center max-w-[250px] bg-white rounded-2xl shadow-card-horizontal pb-5">
                            <p className="text-black font-semibold text-2xl text-center py-5">
                                Hexagrama
                                <br />
                                Principial
                            </p>
                            <p className="text-3xl font-pacifico font-bold text-center text-black pb-5">
                                {hexagrama[7] + ' ' + hexagrama[6]}
                            </p>
                            <img
                                src={require('../../../assets/images/iching/' + hexagrama[7] + '.png')}
                                alt=""
                                width={250}
                                height={250}
                            />
                        </div>
                        {complemento !== null && (
                            <div className="flex flex-col items-center justify-center max-w-[250px] bg-white rounded-2xl shadow-card-horizontal pb-5">
                                <p className="text-black font-semibold text-2xl text-center py-5">
                                    Hexagrama Complementario
                                </p>
                                <p className="text-3xl font-pacifico font-bold text-center text-black pb-5">
                                    {complemento.numero + ' ' + complemento.nombre}
                                </p>
                                <img
                                    src={require('../../../assets/images/iching/' + complemento.numero + '.png')}
                                    alt=""
                                    width={250}
                                    height={250}
                                />
                            </div>
                        )}
                    </div>
                )}
                {showResponse ? (
                    <div className="absolute w-[96vw] mini:w-[96%] xl:w-[90vw] h-[90%] top-[2%] left-[5%] bg-zinc-200 shadow-2xl flex flex-row gap-5 rounded-xl py-5 mini:px-2 md:px-5">
                        <div className="mini:w-full xl:w-2/3 flex flex-col gap-3">
                            <textarea
                                className="w-full h-full resize-none rounded-lg mini:p-2 md:p-5"
                                value={response}
                                onChange={(e) => setResponse(e.target.value)}
                            />
                            <div className="flex flex-row flex-wrap justify-center gap-1">
                                <ButtonNormal caption="Volver" onClick={() => setShowResponse(false)} />
                                <ButtonNormal caption="Copiar Respuesta" onClick={() => copyResponse(response)} />
                                <ButtonNormal caption="Nueva Pregunta" onClick={() => nuevaPregunta()} />
                                <ButtonNormal caption="Nuevo Cliente" onClick={() => nuevoCliente()} />
                            </div>
                        </div>
                        <div className="w-1/3 flex flex-col gap-3 mini:hidden xl:block">
                            <p className="font-semibold">Consultante: {data.consultante.zodiac}</p>
                            <div
                                className="w-full bg-zinc-50 p-5 rounded-lg text-sm overflow-y-auto"
                                id="consultante"
                            ></div>
                        </div>
                    </div>
                ) : null}
            </div>
        </div>
    )
}

export default IchingPregunta
