import { AllCardsType } from '../../../data/all-cards'
import { DataTypePregunta } from '../../../interfaces/utils'

export const interpret = (cards: AllCardsType[], data: DataTypePregunta, user: string, type: string) => {
    let consult = `Por favor, realiza una la interpretacion de la Tirada General de Tarot en un lenguaje sencillo de entender, pero hazla lo mas detallada y extensa posible y que no sobrepase los 300 tokens, para ${
        data.consultante.name
    } de signo zodiacal ${
        data.consultante.zodiac
    }, ten en cuenta es esta tirada su signo zodiacal y su personalidad número ${
        data.consultante.personality
    } según la numerología, ${
        data.consulta.length > 0
            ? ' incluye en esta tirada general una respuesta al asunto que el consultante está consultando en forma específica, brindándole una respuesta concreta y definitiva, utilizando tu criterio según la interpretación que realices con las cartas te proporciono a continuación para responder esta consulta específica del consultante: ' +
              data.consulta
            : ''
    } y organiza la tirada de la siguiente forma: En el amor las cartas ${cards[0].name}${
        cards[0].position === 'invertida' ? ' invertida' : ''
    } en la situación actual, ${cards[1].name}${
        cards[1].position === 'invertida' ? ' invertida' : ''
    } en las influencias, ${cards[2].name}${
        cards[2].position === 'invertida' ? ' invertida' : ''
    }  en los desafíos y/o obstáculos, ${cards[3].name}${
        cards[3].position === 'invertida' ? ' invertida' : ''
    } en el consejo y ${cards[4].name}${
        cards[4].position === 'invertida' ? ' invertida' : ''
    } en el resultado final; en el trabajo:, ${cards[5].name}${
        cards[5].position === 'invertida' ? ' invertida' : ''
    } en la situación actual, ${cards[6].name}${
        cards[6].position === 'invertida' ? ' invertida' : ''
    } en las influencias ${cards[7].name}${
        cards[7].position === 'invertida' ? ' invertida' : ''
    } en los desafíos y/o obstáculos, ${cards[8].name}${
        cards[8].position === 'invertida' ? ' invertida' : ''
    } en el consejo y ${cards[9].name}${
        cards[9].position === 'invertida' ? ' invertida' : ''
    } en el resultado final; en el dinero: ${cards[10].name}${
        cards[10].position === 'invertida' ? ' invertida' : ''
    } en la situación actual, ${cards[11].name}${
        cards[11].position === 'invertida' ? ' invertida' : ''
    } en las influencias, ${cards[12].name}${
        cards[12].position === 'invertida' ? ' invertida' : ''
    } en los desafíos y/o obstáculos, ${cards[13].name}${
        cards[13].position === 'invertida' ? ' invertida' : ''
    } en el consejo y ${cards[14].name}${
        cards[14].position === 'invertida' ? ' invertida' : ''
    } en el resultado final; en la salud: ${cards[15].name}${
        cards[15].position === 'invertida' ? ' invertida' : ''
    } en la situacion actual, ${cards[16].name}${
        cards[16].position === 'invertida' ? ' invertida' : ''
    } en las influencias, ${cards[17].name}${
        cards[17].position === 'invertida' ? ' invertida' : ''
    } en los desafíos y/o obstáculos, ${cards[18].name}${
        cards[18].position === 'invertida' ? ' invertida' : ''
    } en el consejo y ${cards[19].name}${
        cards[19].position === 'invertida' ? ' invertida' : ''
    } en el resultado final; y por último en la familia: ${cards[20].name}${
        cards[20].position === 'invertida' ? ' invertida' : ''
    } en la situación actual, ${cards[21].name}${
        cards[21].position === 'invertida' ? ' invertida' : ''
    } en las influencias, ${cards[22].name}${
        cards[22].position === 'invertida' ? ' invertida' : ''
    } en los desafíos y/o obstáculos, ${cards[23].name}${
        cards[23].position === 'invertida' ? ' invertida' : ''
    } en el consejo y ${cards[24].name}${
        cards[24].position === 'invertida' ? ' invertida' : ''
    } en el resultado final. Dirígete directamente al consultante, no utilices la tercera persona refiriendote a la consultante, aún cuando las consultas se encuentren en tercera persona, adapta la respuesta para que quede en segunda persona. Incluye después de la interpretación de cada una de las aspectos desarrollados, en un nuevo párrafo, un resúmen que aclare concretamente lo que las cartas le quieren transmitir al consultante en cada aspecto. ${
        data.consulta.length > 9
            ? 'Si el consultante esta haciendo una consulta sobre algunos asuntos específicos, por favor, elabora una respuesta o más respuestas concretas al final de la interpretación que tú como tarotista experta desarrollaras en base al resultado de la tirada general, su signo zodiacal, su edad, su número de personalidad, etc.'
            : ''
    }`

    let info = {
        consult: consult,
        user: user,
        clientName: data.consultante.name,
        clientAnswer: data.consulta,
        cards: cards,
        type: type,
    }

    return info
}
