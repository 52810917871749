export interface AllCardsType {
  id: string;
  name: string;
  image: string;
  url: string;
  selected: boolean;
  place: number;
  position: string;
  casa: number;
  value: number;
}

export const AllCards: AllCardsType[] = [
  {
    id: "01",
    name: "El Mago",
    image: "el-mago.jpg",
    url: "mayores",
    selected: false,
    place: 0,
    position: "derecha",
    casa: 0,
    value: 1,
  },
  {
    id: "02",
    name: "La Papisa",
    image: "la-papisa.jpg",
    url: "mayores",
    selected: false,
    place: 0,
    position: "derecha",
    casa: 0,
    value: 0,
  },
  {
    id: "03",
    name: "La Emperatriz",
    image: "la-emperatriz.jpg",
    url: "mayores",
    selected: false,
    place: 0,
    position: "derecha",
    casa: 0,
    value: 1,
  },
  {
    id: "04",
    name: "El Emperador",
    image: "el-emperador.jpg",
    url: "mayores",
    selected: false,
    place: 0,
    position: "derecha",
    casa: 0,
    value: 1,
  },
  {
    id: "05",
    name: "El Papa",
    image: "el-papa.jpg",
    url: "mayores",
    selected: false,
    place: 0,
    position: "derecha",
    casa: 0,
    value: 0,
  },
  {
    id: "06",
    name: "El Enamorado",
    image: "los-enamorados.jpg",
    url: "mayores",
    selected: false,
    place: 0,
    position: "derecha",
    casa: 0,
    value: 0,
  },
  {
    id: "07",
    name: "El Carro",
    image: "el-carro.jpg",
    url: "mayores",
    selected: false,
    place: 0,
    position: "derecha",
    casa: 0,
    value: 1,
  },
  {
    id: "08",
    name: "La Justicia",
    image: "la-justicia.jpg",
    url: "mayores",
    selected: false,
    place: 0,
    position: "derecha",
    casa: 0,
    value: 0,
  },
  {
    id: "09",
    name: "El Ermitaño",
    image: "el-ermitanio.jpg",
    url: "mayores",
    selected: false,
    place: 0,
    position: "derecha",
    casa: 0,
    value: 0,
  },
  {
    id: "10",
    name: "La Rueda de la Fortuna",
    image: "la-rueda-de-la-fortuna.jpg",
    url: "mayores",
    selected: false,
    place: 0,
    position: "derecha",
    casa: 0,
    value: 0,
  },
  {
    id: "11",
    name: "La Fuerza",
    image: "la-fuerza.jpg",
    url: "mayores",
    selected: false,
    place: 0,
    position: "derecha",
    casa: 0,
    value: 1,
  },
  {
    id: "12",
    name: "El Colgado",
    image: "el-colgado.jpg",
    url: "mayores",
    selected: false,
    place: 0,
    position: "derecha",
    casa: 0,
    value: -1,
  },
  {
    id: "13",
    name: "La Muerte",
    image: "la-muerte.jpg",
    url: "mayores",
    selected: false,
    place: 0,
    position: "derecha",
    casa: 0,
    value: -1,
  },
  {
    id: "14",
    name: "La Templanza",
    image: "la-templanza.jpg",
    url: "mayores",
    selected: false,
    place: 0,
    position: "derecha",
    casa: 0,
    value: 1,
  },
  {
    id: "15",
    name: "El Diablo",
    image: "el-diablo.jpg",
    url: "mayores",
    selected: false,
    place: 0,
    position: "derecha",
    casa: 0,
    value: -1,
  },
  {
    id: "16",
    name: "La Torre",
    image: "la-torre.jpg",
    url: "mayores",
    selected: false,
    place: 0,
    position: "derecha",
    casa: 0,
    value: -1,
  },
  {
    id: "17",
    name: "La Estrella",
    image: "la-estrella.jpg",
    url: "mayores",
    selected: false,
    place: 0,
    position: "derecha",
    casa: 0,
    value: 1,
  },
  {
    id: "18",
    name: "La Luna",
    image: "la-luna.jpg",
    url: "mayores",
    selected: false,
    place: 0,
    position: "derecha",
    casa: 0,
    value: -1,
  },
  {
    id: "19",
    name: "El Sol",
    image: "el-sol.jpg",
    url: "mayores",
    selected: false,
    place: 0,
    position: "derecha",
    casa: 0,
    value: 1,
  },
  {
    id: "20",
    name: "El Juicio",
    image: "el-juicio.jpg",
    url: "mayores",
    selected: false,
    place: 0,
    position: "derecha",
    casa: 0,
    value: 1,
  },
  {
    id: "21",
    name: "El Mundo",
    image: "el-mundo.jpg",
    url: "mayores",
    selected: false,
    place: 0,
    position: "derecha",
    casa: 0,
    value: 1,
  },
  {
    id: "22",
    name: "El Loco",
    image: "el-loco.jpg",
    url: "mayores",
    selected: false,
    place: 0,
    position: "derecha",
    casa: 0,
    value: 0,
  },
  {
    id: "1o",
    name: "As de Oros",
    image: "as.jpg",
    url: "menores/oros",
    selected: false,
    place: 0,
    position: "derecha",
    casa: 0,
    value: 1,
  },
  {
    id: "2o",
    name: "Dos de Oros",
    image: "dos.jpg",
    url: "menores/oros",
    selected: false,
    place: 0,
    position: "derecha",
    casa: 0,
    value: 0,
  },
  {
    id: "3o",
    name: "Tres de Oros",
    image: "tres.jpg",
    url: "menores/oros",
    selected: false,
    place: 0,
    position: "derecha",
    casa: 0,
    value: 1,
  },
  {
    id: "4o",
    name: "Cuatro de Oros",
    image: "cuatro.jpg",
    url: "menores/oros",
    selected: false,
    place: 0,
    position: "derecha",
    casa: 0,
    value: -1,
  },
  {
    id: "5o",
    name: "Cinco de Oros",
    image: "cinco.jpg",
    url: "menores/oros",
    selected: false,
    place: 0,
    position: "derecha",
    casa: 0,
    value: -1,
  },
  {
    id: "6o",
    name: "Seis de Oros",
    image: "seis.jpg",
    url: "menores/oros",
    selected: false,
    place: 0,
    position: "derecha",
    casa: 0,
    value: 1,
  },
  {
    id: "7o",
    name: "Siete de Oros",
    image: "siete.jpg",
    url: "menores/oros",
    selected: false,
    place: 0,
    position: "derecha",
    casa: 0,
    value: 1,
  },
  {
    id: "8o",
    name: "Ocho de Oros",
    image: "ocho.jpg",
    url: "menores/oros",
    selected: false,
    place: 0,
    position: "derecha",
    casa: 0,
    value: 1,
  },
  {
    id: "9o",
    name: "Nueve de Oros",
    image: "nueve.jpg",
    url: "menores/oros",
    selected: false,
    place: 0,
    position: "derecha",
    casa: 0,
    value: 1,
  },
  {
    id: "10o",
    name: "Diez de Oros",
    image: "diez.jpg",
    url: "menores/oros",
    selected: false,
    place: 0,
    position: "derecha",
    casa: 0,
    value: 1,
  },
  {
    id: "So",
    name: "Sota de Oros",
    image: "sota.jpg",
    url: "menores/oros",
    selected: false,
    place: 0,
    position: "derecha",
    casa: 0,
    value: 1,
  },
  {
    id: "Co",
    name: "Caballero de Oros",
    image: "caballo.jpg",
    url: "menores/oros",
    selected: false,
    place: 0,
    position: "derecha",
    casa: 0,
    value: 1,
  },
  {
    id: "RAo",
    name: "Reina de Oros",
    image: "reina.jpg",
    url: "menores/oros",
    selected: false,
    place: 0,
    position: "derecha",
    casa: 0,
    value: 1,
  },
  {
    id: "RYo",
    name: "Rey de Oros",
    image: "rey.jpg",
    url: "menores/oros",
    selected: false,
    place: 0,
    position: "derecha",
    casa: 0,
    value: 1,
  },
  {
    id: "1c",
    name: "As de Copas",
    image: "as.jpg",
    url: "menores/copas",
    selected: false,
    place: 0,
    position: "derecha",
    casa: 0,
    value: 1,
  },
  {
    id: "2c",
    name: "Dos de Copas",
    image: "dos.jpg",
    url: "menores/copas",
    selected: false,
    place: 0,
    position: "derecha",
    casa: 0,
    value: 1,
  },
  {
    id: "3c",
    name: "Tres de Copas",
    image: "tres.jpg",
    url: "menores/copas",
    selected: false,
    place: 0,
    position: "derecha",
    casa: 0,
    value: 1,
  },
  {
    id: "4c",
    name: "Cuatro de Copas",
    image: "cuatro.jpg",
    url: "menores/copas",
    selected: false,
    place: 0,
    position: "derecha",
    casa: 0,
    value: 0,
  },
  {
    id: "5c",
    name: "Cinco de Copas",
    image: "cinco.jpg",
    url: "menores/copas",
    selected: false,
    place: 0,
    position: "derecha",
    casa: 0,
    value: -1,
  },
  {
    id: "6c",
    name: "Seis de Copas",
    image: "seis.jpg",
    url: "menores/copas",
    selected: false,
    place: 0,
    position: "derecha",
    casa: 0,
    value: 1,
  },
  {
    id: "7c",
    name: "Siete de Copas",
    image: "siete.jpg",
    url: "menores/copas",
    selected: false,
    place: 0,
    position: "derecha",
    casa: 0,
    value: 0,
  },
  {
    id: "8c",
    name: "Ocho de Copas",
    image: "ocho.jpg",
    url: "menores/copas",
    selected: false,
    place: 0,
    position: "derecha",
    casa: 0,
    value: 0,
  },
  {
    id: "9c",
    name: "Nueve de Copas",
    image: "nueve.jpg",
    url: "menores/copas",
    selected: false,
    place: 0,
    position: "derecha",
    casa: 0,
    value: 1,
  },
  {
    id: "10c",
    name: "Diez de Copas",
    image: "diez.jpg",
    url: "menores/copas",
    selected: false,
    place: 0,
    position: "derecha",
    casa: 0,
    value: 1,
  },
  {
    id: "Sc",
    name: "Sota de Copas",
    image: "sota.jpg",
    url: "menores/copas",
    selected: false,
    place: 0,
    position: "derecha",
    casa: 0,
    value: 1,
  },
  {
    id: "Cc",
    name: "Caballero de Copas",
    image: "caballo.jpg",
    url: "menores/copas",
    selected: false,
    place: 0,
    position: "derecha",
    casa: 0,
    value: 1,
  },
  {
    id: "RAc",
    name: "Reina de Copas",
    image: "reina.jpg",
    url: "menores/copas",
    selected: false,
    place: 0,
    position: "derecha",
    casa: 0,
    value: 1,
  },
  {
    id: "RYc",
    name: "Rey de Copas",
    image: "rey.jpg",
    url: "menores/copas",
    selected: false,
    place: 0,
    position: "derecha",
    casa: 0,
    value: 1,
  },
  {
    id: "1e",
    name: "As de Espadas",
    image: "as.jpg",
    url: "menores/espadas",
    selected: false,
    place: 0,
    position: "derecha",
    casa: 0,
    value: 1,
  },
  {
    id: "2e",
    name: "Dos de Espadas",
    image: "dos.jpg",
    url: "menores/espadas",
    selected: false,
    place: 0,
    position: "derecha",
    casa: 0,
    value: 0,
  },
  {
    id: "3e",
    name: "Tres de Espadas",
    image: "tres.jpg",
    url: "menores/espadas",
    selected: false,
    place: 0,
    position: "derecha",
    casa: 0,
    value: -1,
  },
  {
    id: "4e",
    name: "Cuatro de Espadas",
    image: "cuatro.jpg",
    url: "menores/espadas",
    selected: false,
    place: 0,
    position: "derecha",
    casa: 0,
    value: 0,
  },
  {
    id: "5e",
    name: "Cinco de Espadas",
    image: "cinco.jpg",
    url: "menores/espadas",
    selected: false,
    place: 0,
    position: "derecha",
    casa: 0,
    value: -1,
  },
  {
    id: "6e",
    name: "Seis de Espadas",
    image: "seis.jpg",
    url: "menores/espadas",
    selected: false,
    place: 0,
    position: "derecha",
    casa: 0,
    value: 0,
  },
  {
    id: "7e",
    name: "Siete de Espadas",
    image: "siete.jpg",
    url: "menores/espadas",
    selected: false,
    place: 0,
    position: "derecha",
    casa: 0,
    value: -1,
  },
  {
    id: "8e",
    name: "Ocho de Espadas",
    image: "ocho.jpg",
    url: "menores/espadas",
    selected: false,
    place: 0,
    position: "derecha",
    casa: 0,
    value: -1,
  },
  {
    id: "9e",
    name: "Nueve de Espadas",
    image: "nueve.jpg",
    url: "menores/espadas",
    selected: false,
    place: 0,
    position: "derecha",
    casa: 0,
    value: -1,
  },
  {
    id: "10e",
    name: "Diez de Espadas",
    image: "diez.jpg",
    url: "menores/espadas",
    selected: false,
    place: 0,
    position: "derecha",
    casa: 0,
    value: -1,
  },
  {
    id: "Se",
    name: "Sota de Espadas",
    image: "sota.jpg",
    url: "menores/espadas",
    selected: false,
    place: 0,
    position: "derecha",
    casa: 0,
    value: 0,
  },
  {
    id: "Ce",
    name: "Caballero de Espadas",
    image: "caballo.jpg",
    url: "menores/espadas",
    selected: false,
    place: 0,
    position: "derecha",
    casa: 0,
    value: -1,
  },
  {
    id: "RAe",
    name: "Reina de Espadas",
    image: "reina.jpg",
    url: "menores/espadas",
    selected: false,
    place: 0,
    position: "derecha",
    casa: 0,
    value: 0,
  },
  {
    id: "RYe",
    name: "Rey de Espadas",
    image: "rey.jpg",
    url: "menores/espadas",
    selected: false,
    place: 0,
    position: "derecha",
    casa: 0,
    value: 0,
  },
  {
    id: "1b",
    name: "As de Bastos",
    image: "as.jpg",
    url: "menores/bastos",
    selected: false,
    place: 0,
    position: "derecha",
    casa: 0,
    value: 1,
  },
  {
    id: "2b",
    name: "Dos de Bastos",
    image: "dos.jpg",
    url: "menores/bastos",
    selected: false,
    place: 0,
    position: "derecha",
    casa: 0,
    value: 1,
  },
  {
    id: "3b",
    name: "Tres de Bastos",
    image: "tres.jpg",
    url: "menores/bastos",
    selected: false,
    place: 0,
    position: "derecha",
    casa: 0,
    value: 1,
  },
  {
    id: "4b",
    name: "Cuatro de Bastos",
    image: "cuatro.jpg",
    url: "menores/bastos",
    selected: false,
    place: 0,
    position: "derecha",
    casa: 0,
    value: 1,
  },
  {
    id: "5b",
    name: "Cinco de Bastos",
    image: "cinco.jpg",
    url: "menores/bastos",
    selected: false,
    place: 0,
    position: "derecha",
    casa: 0,
    value: 0,
  },
  {
    id: "6b",
    name: "Seis de Bastos",
    image: "seis.jpg",
    url: "menores/bastos",
    selected: false,
    place: 0,
    position: "derecha",
    casa: 0,
    value: 1,
  },
  {
    id: "7b",
    name: "Siete de Bastos",
    image: "siete.jpg",
    url: "menores/bastos",
    selected: false,
    place: 0,
    position: "derecha",
    casa: 0,
    value: 0,
  },
  {
    id: "8b",
    name: "Ocho de Bastos",
    image: "ocho.jpg",
    url: "menores/bastos",
    selected: false,
    place: 0,
    position: "derecha",
    casa: 0,
    value: 1,
  },
  {
    id: "9b",
    name: "Nueve de Bastos",
    image: "nueve.jpg",
    url: "menores/bastos",
    selected: false,
    place: 0,
    position: "derecha",
    casa: 0,
    value: 0,
  },
  {
    id: "10b",
    name: "Diez de Bastos",
    image: "diez.jpg",
    url: "menores/bastos",
    selected: false,
    place: 0,
    position: "derecha",
    casa: 0,
    value: 0,
  },
  {
    id: "Sb",
    name: "Sota de Bastos",
    image: "sota.jpg",
    url: "menores/bastos",
    selected: false,
    place: 0,
    position: "derecha",
    casa: 0,
    value: 1,
  },
  {
    id: "Cb",
    name: "Caballero de Bastos",
    image: "caballo.jpg",
    url: "menores/bastos",
    selected: false,
    place: 0,
    position: "derecha",
    casa: 0,
    value: 1,
  },
  {
    id: "RAb",
    name: "Reina de Bastos",
    image: "reina.jpg",
    url: "menores/bastos",
    selected: false,
    place: 0,
    position: "derecha",
    casa: 0,
    value: 1,
  },
  {
    id: "RYb",
    name: "Rey de Bastos",
    image: "rey.jpg",
    url: "menores/bastos",
    selected: false,
    place: 0,
    position: "derecha",
    casa: 0,
    value: 1,
  },
];

/*
{
    id: '',
    name: '',
    image: '',
    url: 'menores/bastos',
},
*/
