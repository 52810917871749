export const Italian = {
  translation: {
    name: "Nome",
    throw_of_yes_or_no: "Lancio di sì o no",
    how_it_works: "Come Funziona",
    about_tarot: "Sui Tarocchi",
    consults: "Consulta",
    clients: "Clienti",
    contact: "Contatto",
    birthdate: "Data di Nascita",
    ask: "Chiedere",
    done: "Fatto",
    shuffle: "Mescolare",
    interpret: "Interpretare",
    edit: "Modificare",
    new_consult: "Nuovo Consulto",
    new_client: "Nuovo Cliente",
    see_text: "Vedi Testo",
    return: "Ritorno",
  },
};
