import React from 'react'
import styles from '../love/love.module.css'
import Diversity1Icon from '@mui/icons-material/Diversity1'

interface FamilyType {
    isVisible: boolean
}

const Family: React.FC<FamilyType> = (props: FamilyType): JSX.Element => {
    return (
        <div className={styles.body}>
            <div className={styles.body_content}>
                <div className={styles.title_container}>
                    <div className={props.isVisible ? styles.circle + ' ' + styles.circle_show : styles.circle}>
                        <Diversity1Icon sx={{ color: '#ffffff', fontSize: 40 }} />
                    </div>
                    <div className={styles.sobrecircle}></div>
                    <div className={styles.title}>
                        FAMILIA
                        <div className={styles.line}></div>
                    </div>
                </div>
                <div className={styles.parrafo}>
                    <p>
                        La familia es uno de los pilares más importantes en nuestras vidas. Es nuestro apoyo emocional,
                        nuestra red de seguridad y nuestra fuente de amor incondicional. Pero a veces, las relaciones
                        familiares pueden ser complicadas y desafiantes.
                    </p>
                    <p>
                        A través de las cartas del tarot, podemos explorar los patrones familiares y descubrir nuevas
                        formas de comunicación y entendimiento. Ya sea que estés tratando de mejorar una relación
                        existente o buscando sanar heridas del pasado.
                    </p>
                </div>
            </div>
            <img
                src={require('../../../assets/images/homepage/child-1835730_1280.jpg')}
                alt=""
                className={props.isVisible ? styles.img_love + ' ' + styles.show : styles.img_love}
            />
        </div>
    )
}

export default Family
