export const Portuguese = {
  translation: {
    name: "Nome",
    throw_of_yes_or_no: "Lance de sim ou não",
    how_it_works: "Como Funciona",
    about_tarot: "Sobre Tarô",
    consults: "Consulta",
    clients: "Clientes",
    contact: "Contato",
    birthdate: "Data de Nascimento",
    ask: "Pergunta",
    done: "Feito",
    shuffle: "Embaralhar",
    interpret: "Interpretar",
    edit: "Editar",
    new_consult: "Nova Consulta",
    new_client: "Novo Cliente",
    see_text: "Ver Texto",
    return: "Retornar",
  },
};
