import { IIndexable } from '../interfaces/utils'

export const clasificacion: IIndexable = {
    '01': 1,
    '02': 1,
    '03': 1,
    '04': 1,
    '05': 1,
    '06': 1,
    '07': 1,
    '08': 1,
    '09': -1,
    10: 1,
    11: 1,
    12: -1,
    13: -1,
    14: 1,
    15: -1,
    16: -1,
    17: 1,
    18: -1,
    19: 1,
    20: 1,
    21: 1,
    22: -1,
    '1c': 1,
    '2c': 1,
    '3c': 1,
    '4c': -1,
    '5c': -1,
    '6c': 1,
    '7c': -1,
    '8c': -1,
    '9c': 1,
    '10c': 1,
    Sc: 1,
    Cc: 1,
    RAc: 1,
    RYc: 1,
    '1e': -1,
    '2e': -1,
    '3e': -1,
    '4e': -1,
    '5e': -1,
    '6e': 1,
    '7e': -1,
    '8e': -1,
    '9e': -1,
    '10e': -1,
    Se: -1,
    Ce: -1,
    RAe: -1,
    RYe: -1,
    '1b': 1,
    '2b': 1,
    '3b': 1,
    '4b': 1,
    '5b': -1,
    '6b': 1,
    '7b': 1,
    '8b': 1,
    '9b': -1,
    '10b': -1,
    Sb: 1,
    Cb: 1,
    RAb: 1,
    RYb: 1,
    '1o': 1,
    '2o': 1,
    '3o': 1,
    '4o': -1,
    '5o': -1,
    '6o': 1,
    '7o': 1,
    '8o': 1,
    '9o': 1,
    '10o': 1,
    So: 1,
    Co: 1,
    RAo: 1,
    RYo: 1,
}
