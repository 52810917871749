import React from 'react'
import styles from './love.module.css'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'

interface LoveType {
    isVisible: boolean
}

const Love: React.FC<LoveType> = (props: LoveType): JSX.Element => {
    return (
        <div className={styles.body}>
            <div className={styles.body_content}>
                <div className={styles.title_container}>
                    <div className={props.isVisible ? styles.circle + ' ' + styles.circle_show : styles.circle}>
                        <FavoriteBorderIcon sx={{ color: '#ffffff', fontSize: 40 }} />
                    </div>
                    <div className={styles.sobrecircle}></div>
                    <div className={styles.title}>
                        AMOR
                        <div className={styles.line}></div>
                    </div>
                </div>
                <div className={styles.parrafo}>
                    <p>
                        El amor es uno de los aspectos más importantes de nuestra vida. Nos trae felicidad, pasión y nos
                        ayuda a crecer como seres humanos. Pero también puede ser una fuente de dolor y confusión.
                    </p>
                    <p>
                        A través de las cartas del tarot, podemos obtener una comprensión más profunda de nuestros
                        sentimientos y emociones. Podemos explorar las posibilidades y descubrir nuevas formas de
                        abordar las situaciones amorosas.
                    </p>
                </div>
            </div>
            <img
                src={require('../../../assets/images/homepage/woman-gd817d42f0_1280.jpg')}
                alt=""
                className={props.isVisible ? styles.img_love + ' ' + styles.show : styles.img_love}
            />
        </div>
    )
}

export default Love
