import React from 'react'
import styles from './homepage.module.css'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import Phrases from './phrases'
import Slider from './slider'

const Homepage: React.FC = (): JSX.Element => {
    return (
        <div className={styles.body}>
            <div className={styles.content}>
                <div className={styles.header}>
                    <div className={styles.title_container}>
                        <div className={styles.title1}>EL CAMINO DE</div>
                        <div className={styles.title2}>LA SUPERACION</div>
                    </div>
                    <div className={styles.frase_container}>
                        <div className={styles.title_frase}>
                            <Phrases />
                        </div>
                        <div className={styles.line}></div>
                        <div className={styles.frase}>
                            Convierte tus dudas e indecisiones en certezas accediendo a un antiguo plano de respuestas
                            de la mano del tarot.
                        </div>
                    </div>
                </div>

                <div className={styles.swiper_container}>
                    <Slider />
                </div>
            </div>
            {/* <img
        src={require("../../assets/images/homepage/background.png")}
        alt=""
        className={styles.cards}
      /> */}
        </div>
    )
}

export default Homepage
