import React from 'react'
import WorkOutlineIcon from '@mui/icons-material/WorkOutline'
import styles from '../love/love.module.css'

interface JobType {
    isVisible: boolean
}

const Job: React.FC<JobType> = (props: JobType): JSX.Element => {
    return (
        <div className={styles.body}>
            <div className={styles.body_content}>
                <div className={styles.title_container}>
                    <div className={props.isVisible ? styles.circle + ' ' + styles.circle_show : styles.circle}>
                        <WorkOutlineIcon sx={{ color: '#ffffff', fontSize: 40 }} />
                    </div>
                    <div className={styles.sobrecircle}></div>
                    <div className={styles.title}>
                        NEGOCIOS
                        <div className={styles.line}></div>
                    </div>
                </div>
                <div className={styles.parrafo}>
                    <p>
                        Los negocios son una parte fundamental de nuestras vidas, ya que nos permiten crecer
                        económicamente y alcanzar nuestros objetivos profesionales. Sin embargo, también pueden ser
                        desafiantes y estresantes.
                    </p>
                    <p>
                        A través de las cartas del tarot, podemos explorar tus deseos y necesidades en relación a tus
                        negocios. Podemos descubrir nuevas formas de abordar las situaciones de negocios y encontrar
                        soluciones a tus desafíos.
                    </p>
                </div>
            </div>
            <img
                src={require('../../../assets/images/homepage/smiling-woman-1340662_1280.jpg')}
                alt=""
                className={props.isVisible ? styles.img_love + ' ' + styles.show : styles.img_love}
            />
        </div>
    )
}

export default Job
