import React from "react";
import styles from "./result-manual-tres.module.css";
import {
  ArcanosMayores2Type,
  ArcanosMayoresType,
} from "../../../../interfaces/arcanos-mayores";
import { ArcanosMayores, arcanosMayores } from "../../../../data/data";
import { IIndexable, getIndexCard } from "../../../../interfaces/utils";

interface ResultManualTresType {
  cards: [];
  reset: () => void;
  answer: string;
}

const ResultManualTres = (props: ResultManualTresType) => {
  const mayores: ArcanosMayores2Type[] = ArcanosMayores;

  return (
    <div className={styles.body}>
      <div className={styles.title}>Interpretación Resultado 3 Cartas</div>
      <div className={styles.result_container}>
        {props.cards.map((card: any) => (
          <div className={styles.card_container}>
            <div className={styles.card_name}>
              {
                mayores[
                  mayores.findIndex((elem: any) => elem.codigo === card.card)
                ].name
              }{" "}
              {card.position === "invertida" ? (
                <span className={styles.title_invertida}>Invertida</span>
              ) : (
                ""
              )}
            </div>
            <div className={styles.info_container}>
              <div className={styles.concept_header}>
                <img
                  src={require("../../../../assets/cards/mayores/" +
                    mayores[getIndexCard(card.card)].image)}
                  alt=""
                  className={
                    card.position === "derecha"
                      ? styles.card
                      : styles.card + " " + styles.card_invertida
                  }
                />
                <p className={styles.stdtext}>
                  {mayores[getIndexCard(card.card)].represent}
                </p>
                <span className={styles.boldtext}>Como resultado final: </span>
                <p className={styles.stdtext}>
                  {mayores[getIndexCard(card.card)].result}
                </p>
                <span className={styles.boldtext}>Como persona: </span>
                <p className={styles.stdtext}>
                  {mayores[getIndexCard(card.card)].person}
                </p>
                <span className={styles.boldtext}>Como situación: </span>
                <p className={styles.stdtext}>
                  {mayores[getIndexCard(card.card)].situation}
                </p>
              </div>
              <div className={styles.title_answer}>
                {props.answer[0].toUpperCase() + props.answer.slice(1)}
              </div>
              <p className={styles.stdtext}>
                {
                  (mayores as IIndexable)[getIndexCard(card.card)][
                    card.position
                  ][props.answer]
                }
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ResultManualTres;
