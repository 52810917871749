import React from "react";
import styles from "./about.module.css";

const About: React.FC = (): JSX.Element => {
  return (
    <div className={styles.body}>
      <div className={styles.left}>
        <div className={styles.cards}>
          <img
            src={require("../../assets/cards/mayores/el-mago.jpg")}
            alt=""
            className={styles.img}
          />
          <img
            src={require("../../assets/images/about/el-mago-1.jpg")}
            alt=""
            className={styles.img}
          />
          <img
            src={require("../../assets/images/about/el-mago-6.jpg")}
            alt=""
            className={styles.img}
          />
          <img
            src={require("../../assets/images/about/el-mago-7.jpg")}
            alt=""
            className={styles.img}
          />
          <img
            src={require("../../assets/images/about/el-mago-8.jpg")}
            alt=""
            className={styles.img}
          />
          <img
            src={require("../../assets/images/about/el-mago-9.jpg")}
            alt=""
            className={styles.img}
          />
          <img
            src={require("../../assets/images/about/el-mago-11.jpg")}
            alt=""
            className={styles.img}
          />
          <img
            src={require("../../assets/images/about/el-mago-13.jpg")}
            alt=""
            className={styles.img}
          />
          <img
            src={require("../../assets/images/about/el-mago-14.jpg")}
            alt=""
            className={styles.img}
          />
          <img
            src={require("../../assets/images/about/el-mago-15.jpg")}
            alt=""
            className={styles.img}
          />
          <img
            src={require("../../assets/cards/mayores/el-mago.jpg")}
            alt=""
            className={styles.img}
          />
          <img
            src={require("../../assets/images/about/el-mago-1.jpg")}
            alt=""
            className={styles.img}
          />
          <img
            src={require("../../assets/images/about/el-mago-6.jpg")}
            alt=""
            className={styles.img}
          />
          <img
            src={require("../../assets/images/about/el-mago-7.jpg")}
            alt=""
            className={styles.img}
          />
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.title}>Resúmen Histórico del Tarot</div>
        <p>
          La historia del tarot es fascinante y tiene sus raíces en la
          antigüedad. El tarot es un conjunto de cartas, generalmente 78 en
          total, que se utilizan para la adivinación, el autoconocimiento y la
          reflexión espiritual. Aunque hay diferentes teorías sobre sus orígenes
          exactos, la historia del tarot se remonta al menos al siglo XV en
          Europa.
        </p>
        <p>
          {" "}
          Originalmente, el tarot no se utilizaba con fines adivinatorios, sino
          como un juego de cartas llamado "Tarocchi" o "Tarot". Estas primeras
          cartas del tarot se crearon en Italia y eran similares a las barajas
          de naipes normales, pero con una serie de cartas adicionales con
          imágenes alegóricas. Estas imágenes se convirtieron en los arcanos
          mayores del tarot, que representan personajes y escenas simbólicas.
        </p>
        <p>
          A medida que el tarot se fue popularizando en Europa, comenzaron a
          surgir interpretaciones esotéricas y místicas asociadas a las cartas.
          En el siglo XVIII, Antoine Court de Gebelin, un estudioso francés,
          propuso que el tarot estaba basado en la sabiduría egipcia y que
          contenía claves ocultas de conocimientos antiguos. Esta teoría influyó
          en la posterior asociación del tarot con la tradición egipcia y el
          esoterismo.
        </p>
        <p>
          En el siglo XIX, el tarot experimentó un renacimiento en el ámbito
          esotérico y ocultista con la publicación de "El Tarot de los Bohemios"
          por el ocultista francés Éliphas Lévi. Lévi estableció
          correspondencias entre los arcanos mayores y los principios
          filosóficos y espirituales, y sus ideas influyeron en la forma en que
          se interpreta y utiliza el tarot en la actualidad.
        </p>
        <p>
          Otro hito importante en la historia del tarot fue la creación de la
          baraja de tarot de Waite-Smith en 1909. Esta baraja, diseñada por el
          ocultista Arthur Edward Waite y la artista Pamela Colman Smith,
          introdujo imágenes icónicas y simbólicas que se convirtieron en la
          base de muchas barajas de tarot posteriores. La baraja de Waite-Smith
          es una de las más utilizadas y reconocidas en la actualidad.
        </p>
        <p>
          A lo largo del siglo XX, el tarot se popularizó cada vez más y se
          extendió más allá de los círculos esotéricos. Aparecieron diferentes
          estilos de barajas de tarot, cada una con su propio simbolismo y
          enfoque. Además de su uso para la adivinación, el tarot también se ha
          utilizado como herramienta de autodescubrimiento, meditación y
          crecimiento personal.
        </p>
        <p>
          En la actualidad, el tarot continúa siendo una práctica ampliamente
          utilizada y apreciada. Existen numerosas barajas de tarot, cada una
          con su propia estética y enfoque, y una amplia variedad de libros,
          cursos y recursos disponibles para aprender a interpretar las cartas.
        </p>
        <p>
          Aunque el tarot ha evolucionado a lo largo de los siglos y ha sido
          influenciado por diferentes tradiciones y corrientes espirituales, su
          esencia radica en la capacidad de las cartas para actuar como un
          espejo simbólico que puede ayudarnos a reflexionar sobre nosotros
          mismos, nuestras vidas y nuestro camino espiritual.
        </p>
        <div className={styles.title}>Diferentes Barajas</div>
        <p>
          Existen una gran cantidad de barajas de tarot diferentes, cada una con
          su propio simbolismo, estilo artístico y enfoque interpretativo. A
          continuación, mencionaré algunos de los tipos más conocidos y
          utilizados:
        </p>
        <ol>
          <li>
            <strong>Tarot de Marsella</strong>: Es una de las barajas más
            antiguas y populares. Se caracteriza por su estilo artístico
            medieval y sus imágenes simbólicas.
          </li>
          <li>
            <strong>Tarot Rider-Waite</strong>: Creado por Arthur Edward Waite y
            Pamela Colman Smith en 1909, es una de las barajas más influyentes y
            utilizadas en la actualidad. Sus imágenes icónicas y llenas de
            simbolismo lo convierten en un punto de referencia para muchas otras
            barajas.
          </li>
          <li>
            <strong>Tarot Crowley-Harris</strong> (También conocido como Tarot
            Thoth): Diseñado por el ocultista Aleister Crowley y pintado por
            Lady Frieda Harris, este tarot se basa en la tradición hermética y
            contiene simbolismo complejo y profundo.
          </li>
          <li>
            <strong>Tarot Visconti-Sforza</strong>: Esta baraja se remonta al
            siglo XV y es una de las primeras en la historia del tarot. Se
            caracteriza por su estilo renacentista y sus cartas están adornadas
            con detalles elaborados y dorados.
          </li>
          <li>
            <strong>Tarot de los Ángeles</strong>: Enfocado en la conexión con
            el reino angelical, este tarot presenta imágenes de ángeles y se
            utiliza para obtener guía y mensajes divinos.
          </li>
          <li>
            <strong>Tarot de los Druidas</strong>: Basado en la espiritualidad y
            la sabiduría celta, este tarot utiliza símbolos y arquetipos
            druídicos para su interpretación.
          </li>
          <li>
            <strong>Tarot Egipcio</strong>: Inspirado en la antigua cultura
            egipcia, este tarot presenta jeroglíficos, deidades egipcias y
            escenas de la mitología del Antiguo Egipto.
          </li>
          <li>
            <strong>Tarot Osho Zen</strong>: Esta baraja se basa en las
            enseñanzas del maestro espiritual Osho y utiliza imágenes no
            tradicionales y un lenguaje contemporáneo para reflejar el espíritu
            zen.
          </li>
          <li>
            <strong>Tarot de los Gatos</strong>: Una baraja divertida y
            encantadora que presenta gatos en diversas situaciones y poses,
            brindando un enfoque lúdico al tarot.
          </li>
          <li>
            <strong>Tarot de los Sueños</strong>: Basado en la interpretación de
            los sueños, este tarot utiliza imágenes oníricas y simbólicas para
            explorar el mundo de los sueños y el subconsciente.
          </li>
          <li>
            <strong>Tarot de los Ángeles Guardianes</strong>: Centrado en la
            conexión con los ángeles y las energías protectoras, este tarot
            busca brindar guía y apoyo divino en la vida cotidiana.
          </li>
          <li>
            <strong>Tarot de los Santos</strong>: Con imágenes de santos y
            figuras religiosas, este tarot se basa en la iconografía religiosa
            para explorar aspectos espirituales y morales.
          </li>
          <li>
            <strong>Tarot de los Vampiros</strong>: Una baraja oscura y
            misteriosa que presenta temas vampíricos y góticos, explorando la
            sombra y los aspectos más profundos de la psique.
          </li>
          <li>
            <strong>Tarot de los Elementos</strong>: Este tarot se enfoca en los
            cuatro elementos de la naturaleza (tierra, aire, fuego y agua) y
            utiliza imágenes relacionadas para profundizar en su simbolismo.
          </li>
          <li>
            <strong>Tarot de los Maestros Ascendidos</strong>: Basado en la
            creencia en los maestros ascendidos, este tarot presenta figuras
            espirituales y enseñanzas de guías iluminados.
          </li>
          <li>
            <strong>Tarot Gitano</strong>: Influenciado por la cultura gitana y
            su tradición adivinatoria, este tarot utiliza símbolos gitanos y se
            centra en la lectura intuitiva.
          </li>
          <li>
            <strong>Tarot de los Cristales</strong>: Este tarot combina los
            arquetipos del tarot con las energías y propiedades de los
            cristales, ofreciendo una perspectiva única y holística.
          </li>
        </ol>
        <p>
          Estos son solo algunos ejemplos, pero hay muchas otras barajas de
          tarot disponibles en el mercado, cada una con su propio estilo y
          temática. Es importante elegir una baraja con la que te sientas
          conectado y que resuene contigo en tu práctica personal.
        </p>
      </div>
      <div className={styles.right}>
        <div className={styles.cards}>
          <img
            src={require("../../assets/images/about/el-mago-2.jpg")}
            alt=""
            className={styles.img}
          />
          <img
            src={require("../../assets/images/about/el-mago.jpg")}
            alt=""
            className={styles.img}
          />
          <img
            src={require("../../assets/images/about/el-mago-3.jpg")}
            alt=""
            className={styles.img}
          />
          <img
            src={require("../../assets/images/about/el-mago-4.jpg")}
            alt=""
            className={styles.img}
          />
          <img
            src={require("../../assets/images/about/el-mago-5.jpg")}
            alt=""
            className={styles.img}
          />
          <img
            src={require("../../assets/images/about/el-mago-10.jpg")}
            alt=""
            className={styles.img}
          />
          <img
            src={require("../../assets/images/about/el-mago-12.jpg")}
            alt=""
            className={styles.img}
          />
          <img
            src={require("../../assets/images/about/el-mago-16.jpg")}
            alt=""
            className={styles.img}
          />
          <img
            src={require("../../assets/images/about/el-mago-17.jpg")}
            alt=""
            className={styles.img}
          />
          <img
            src={require("../../assets/images/about/el-mago-18.jpg")}
            alt=""
            className={styles.img}
          />
          <img
            src={require("../../assets/images/about/el-mago-2.jpg")}
            alt=""
            className={styles.img}
          />
          <img
            src={require("../../assets/images/about/el-mago.jpg")}
            alt=""
            className={styles.img}
          />
          <img
            src={require("../../assets/images/about/el-mago-3.jpg")}
            alt=""
            className={styles.img}
          />
          <img
            src={require("../../assets/images/about/el-mago-5.jpg")}
            alt=""
            className={styles.img}
          />
        </div>
      </div>
    </div>
  );
};

export default About;
