import { AllCardsType } from '../../../data/all-cards'
import { clasificacion } from '../../../data/clasification'
import { DataTypePregunta } from '../../../interfaces/utils'

export const interpret = (cards: AllCardsType[], data: DataTypePregunta, user: string, type: string) => {
    let consult = `Para la consulta de tarot de ${data.consultante.name} que necesita saber sobre lo siguiente: ${data.consulta}, ¿Puedes analaizar las siguientes cartas y proporcionar una clasificación según su naturaleza (positivas o negativas)? Las cartas son: ${cards[0].name}, ${cards[1].name}, ${cards[2].name}, ${cards[3].name} y ${cards[4].name}. No quiero el significado de las cartas, solo su clasificación y el resultado, siendo un Si a la respuesta si son mas positivas o un No si son más las negativas Por favor ten en cuenta la influencia sobre la consulta a su signo zodiacal: ${data.consultante.zodiac}.`

    let info = {
        consult: consult,
        user: user,
        clientName: data.consultante.name,
        clientAnswer: data.consulta,
        cards: cards,
        type: type,
    }

    return info
}

export const interpretPropia = (cards: AllCardsType[], data: DataTypePregunta, user: string, type: string) => {
    let consult = `Respuesta a la consulta de ${data.consultante.name} de ${data.consultante.zodiac} sobre ${
        data.consulta
    }:\n\n${cards[0].name}: ${clasificacion[cards[0].id] === 1 ? 'Positiva' : 'Negativa'}\n${cards[1].name}: ${
        clasificacion[cards[1].id] === 1 ? 'Positiva' : 'Negativa'
    }\n${cards[2].name}: ${clasificacion[cards[2].id] === 1 ? 'Positiva' : 'Negativa'}\n${cards[3].name}: ${
        clasificacion[cards[3].id] === 1 ? 'Positiva' : 'Negativa'
    }\n${cards[4].name}: ${clasificacion[cards[4].id] === 1 ? 'Positiva' : 'Negativa'}\n\nResultado: ${
        clasificacion[cards[0].id] +
            clasificacion[cards[1].id] +
            clasificacion[cards[2].id] +
            clasificacion[cards[3].id] +
            clasificacion[cards[4].id] >
        0
            ? 'Positivo'
            : 'Negativo'
    }\n\nLa clasificacion de las cartas arroja un resultado ${
        clasificacion[cards[0].id] +
            clasificacion[cards[1].id] +
            clasificacion[cards[2].id] +
            clasificacion[cards[3].id] +
            clasificacion[cards[4].id] >
        0
            ? 'Positivo para la consulta realizada, por lo tanto la respuesta es un SI.'
            : 'Negativo para la consulta realizada, por lo tanto la respuesta es un NO.'
    }\n\nEspero que esta tirada de tarot ayude a aclarar tus dudas y recuerda que el tarot es una herramienta que brinda una guia y orientación en cuestiones de la vida, pero las decisiones que tomes y los sucesos en tu vida dependen de tus acciones.\n\nTe deseo la mejor de las suertes....`

    let info = {
        consult: consult,
        user: user,
        clientName: data.consultante.name,
        clientAnswer: data.consulta,
        cards: cards,
        type: type,
    }

    return info
}
