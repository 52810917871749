/* eslint-disable no-lone-blocks */
import React from 'react'
import Typewriter from 'typewriter-effect'
import { phrases } from '../../data/phrases'

const Phrases: React.FC = (): JSX.Element => {
    return <Typewriter options={{ loop: true, strings: phrases, autoStart: true }} />
}

export default Phrases
